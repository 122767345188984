import { Component, Fragment, type ChangeEvent, type ReactNode } from 'react';
import Hcaptcha from '@hcaptcha/react-hcaptcha';
import { connect } from 'react-redux';
import { bindActionCreators, type Dispatch } from 'redux';
import { emailRegex, notEmptyRegex } from '../../utils/regex';
import ValidCookieForm from '../Home/ValidCookieForm';
import './ResetPassword.css';
import { setPassword1InResetForm, setPassword2InResetForm } from '../../actions/userActions';
import { setViewMessages } from '../../actions/viewActions';
import {
  isContainLowerCase,
  isContainNumber,
  isContainSpecialCharacter,
  isContainUpperAndLowerCase,
  isContainUpperCase,
  isValidMaxPasswordLength,
  isValidMinPasswordLength,
  isValidPasswordLength,
} from '../../utils/checkPassword';
import { gotoPageAfterXSecond, isLoop, scrollTopInDesktopMode } from '../../utils/commons';
import {
  checkAuth,
  sendResetPasswordEmail,
  updatePassword,
  verifyResetKey,
} from '../../utils/data';
import env from '../../utils/env';
import { clickedElement, segmentEnum, track, trackPage } from '../../utils/segment';
import checkCircle from './img/check-circle-pro.svg';

const customerCareLink =
  'https://support.fabfitfun.com/en/articles/44-how-can-i-contact-customer-care';
const defaultMsg = (
  <span>
    Something is incorrect.{' '}
    <a className="lost" href={customerCareLink}>
      Contact our customer support
    </a>
  </span>
);

interface ResetPasswordProps {
  location: {
    search: string;
    forgotPassword?: {
      email: string;
    };
  };
  password_1: string;
  password_2: string;
  messages: ReactNode[];
  setPassword1InResetForm: (password: string) => void;
  setPassword2InResetForm: (password: string) => void;
  setViewMessages: (messages: ReactNode[]) => void;
}

interface ResetPasswordState {
  isUpdating: boolean;
  isResetting: boolean;
  isValidResetKey: boolean;
  successMessage: string | null;
  resetKey: string | null;
  email: string;
  isLoading: boolean;
  isUpdateSuccess: boolean;
  isValidCookie: boolean;
  isLooping: boolean;
  isResetPasswordRequired: boolean;
  passwordValidateErrorMessages: string[];
  isPopoverOpen: boolean;
  isValidPasswordLength: boolean;
  isContainUpperAndLowerCase: boolean;
  isContainUpperCase: boolean;
  isContainLowerCase: boolean;
  isContainNumber: boolean;
  isContainSpecialCharacter: boolean;
  isValidNewPassword: boolean;
  newPasswordValidateErrorMessages: string[];
  isCloseSuccessMessage: boolean;
  hcaptchaToken: string | null;
  isAddedPointer: boolean;
}

class ResetPassword extends Component<ResetPasswordProps, ResetPasswordState> {
  private timeout: NodeJS.Timeout | undefined;

  state = {
    isUpdating: false,
    isResetting: false,
    isValidResetKey: false,
    successMessage: null,
    resetKey: null,
    email: '',
    isLoading: false,
    isUpdateSuccess: false,
    isValidCookie: false,
    isLooping: true,
    isResetPasswordRequired: false,
    passwordValidateErrorMessages: [],
    isPopoverOpen: false,
    isValidPasswordLength: false,
    isContainUpperAndLowerCase: false,
    isContainUpperCase: false,
    isContainLowerCase: false,
    isContainNumber: false,
    isContainSpecialCharacter: false,
    isValidNewPassword: true,
    newPasswordValidateErrorMessages: [],
    isCloseSuccessMessage: true,
    hcaptchaToken: '',
    isAddedPointer: false,
  };
  constructor() {
    super();
    this.timeout = undefined;
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  componentDidMount() {
    this.props.setViewMessages([]);
    //Verify cookie and switch view accordingly
    checkAuth((res: Response) => {
      var isValidCookie = true,
        hasValidCookie = true;
      let loop = true;
      if (!origin || !res.ok) {
        isValidCookie = false;
        hasValidCookie = false;
        let newSearchUrl = new URLSearchParams(this.props.location.search);
        let resetKey = newSearchUrl.get('key');
        let email = newSearchUrl.get('login');
        let forgotPassword = this.props.location.forgotPassword;
        if (forgotPassword && forgotPassword.email) {
          this.setState({
            ...this.state,
            email: forgotPassword.email,
          });
        }
        let isResetPasswordRequired = newSearchUrl.get('isResetPasswordRequired');
        if (isResetPasswordRequired === 'true') {
          let emailResetPasswordRequired = newSearchUrl.get('email');
          this.setState({
            ...this.state,
            isResetPasswordRequired: true,
            isLoading: false,
            email: emailResetPasswordRequired,
          });
        } else if (resetKey && email) {
          this.setState({ ...this.state, resetKey: resetKey, email: email });
          verifyResetKey(resetKey, email).then((res) => {
            if (res.status) {
              switch (res.status) {
                case 200:
                  this.setState({
                    ...this.state,
                    isValidResetKey: true,
                    isLoading: false,
                    email,
                    resetKey,
                  });
                  break;
                case 400:
                  this.setState({
                    ...this.state,
                    isValidResetKey: false,
                    isLoading: false,
                  });
                  this.props.setViewMessages([
                    <span key="reset-password-expired">
                      <strong>This password reset link has expired.</strong> Please check your inbox
                      and spam folder to confirm that you are clicking on the most recent password
                      reset link sent to you. You can also request a new reset link below.
                    </span>,
                  ]);
                  break;

                default:
                  this.setState({
                    ...this.state,
                    isValidResetKey: false,
                    isLoading: false,
                  });
                  this.props.setViewMessages([defaultMsg]);
              }
            }
          });
        } else {
          this.setState({ ...this.state, isLoading: false });
        }
      }

      if (isValidCookie) {
        if (!isLoop()) {
          loop = false;
          gotoPageAfterXSecond({
            goto_url: env.envURL,
          });
        }
      }
      this.setState({
        ...this.state,
        isValidCookie: isValidCookie,
        isLooping: loop,
      });
      trackPage('Reset Passsword', { has_valid_cookie: hasValidCookie });
    });

    // Bind Analytic Trackig Events
    clickedElement({
      target_element: document.getElementsByClassName('reset-password-button'),
      data: {
        element_name: 'Reset Password',
        option_title: 'User sends themselves a password reset email',
      },
    });

    clickedElement({
      target_element: document.getElementsByClassName('update-password-button'),
      data: {
        element_name: 'Update Password',
        option_title: 'User update password',
      },
    });
  }

  handleUpdatePassword1 = (e: ChangeEvent<HTMLInputElement>) => {
    this.props.setPassword1InResetForm(e.target.value);
    const password_1 = e.target.value;
    const validPwdLenght = isValidPasswordLength(password_1);
    const containNumber = isContainNumber(password_1);
    const containUpperAndLowerCase = isContainUpperAndLowerCase(password_1);
    const containSpecialCharacter = isContainSpecialCharacter(password_1);
    const containLowerCase = isContainLowerCase(password_1);
    const containUpperCase = isContainUpperCase(password_1);
    const isValidNewPassword =
      validPwdLenght && containNumber && containSpecialCharacter && containUpperAndLowerCase;
    let inputValidation = this.state.newPasswordValidateErrorMessages;
    if ((inputValidation.length > 0 && isValidNewPassword) || password_1 === '') {
      inputValidation = [];
    }
    this.setState({
      ...this.state,
      isPopoverOpen: true,
      isValidPasswordLength: validPwdLenght,
      isContainNumber: containNumber,
      isContainUpperAndLowerCase: containUpperAndLowerCase,
      isContainLowerCase: containLowerCase,
      isContainUpperCase: containUpperCase,
      isContainSpecialCharacter: containSpecialCharacter,
      isValidNewPassword: isValidNewPassword,
      newPasswordValidateErrorMessages: inputValidation,
    });
  };

  handleUpdatePassword2 = (e: ChangeEvent<HTMLInputElement>) => {
    this.props.setPassword2InResetForm(e.target.value);
    if (e.target.value !== '' && this.props.password_1 !== e.target.value) {
      this.setState({
        ...this.state,
        passwordValidateErrorMessages: ['Passwords do not match.'],
      });
    } else {
      this.setState({ ...this.state, passwordValidateErrorMessages: [] });
    }
  };

  handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ ...this.state, email: e.target.value });
  };

  handleCloseSuccessMessage = () => {
    clearTimeout(this.timeout);
    this.setState({ ...this.state, isCloseSuccessMessage: true });
  };

  handleResetPassword = () => {
    //Validate Email Address
    const isEmail = emailRegex.test(this.state.email);
    const existsEmail = notEmptyRegex.test(this.state.email);
    if (!existsEmail || !isEmail) {
      if (!existsEmail) {
        this.props.setViewMessages([<span key="email-required">Email address is required.</span>]);
      } else {
        this.props.setViewMessages([
          <span key="email-incorrect">The email address you entered is incorrect.</span>,
        ]);
      }
      return;
    }
    this.setState({
      ...this.state,
      isResetting: true,
    });

    //check Hcaptcha feature flag
    if (window.hcaptcha) {
      window.hcaptcha.execute();
    } else {
      this.handleSendEmail();
    }
  };

  handleSendEmail(hcaptchaToken: string) {
    document.getElementsByClassName('update-password-page')[0].removeAttribute('style');
    if (hcaptchaToken) {
      sendResetPasswordEmail({
        email: this.state.email,
        hcaptchaToken: hcaptchaToken,
      }).then((res) => {
        this.setState({
          ...this.state,
          isResetting: false,
        });
        this.handleResponseSendEmail(res);
      });
    } else {
      sendResetPasswordEmail({ email: this.state.email }).then((res) => {
        this.setState({
          ...this.state,
          isResetting: false,
        });
        this.handleResponseSendEmail(res);
      });
    }
  }

  handleResponseSendEmail(res: Response) {
    if (res.status) {
      switch (res.status) {
        case 200:
          this.props.setViewMessages([]);
          track(segmentEnum.SendResetPasswordEmail, {
            success: true,
          });

          this.setState({
            ...this.state,
            isCloseSuccessMessage: false,
            successMessage: 'Link sent! Check your inbox and SPAM folder for your reset link.',
          });
          //Scroll to Top after received message in Desktop mode
          scrollTopInDesktopMode();
          clearTimeout(this.timeout);
          this.timeout = setTimeout(this.handleCloseSuccessMessage, 5000);
          break;
        case 400:
        case 422:
          res.json().then((result) => {
            var failedReason = 'We cannot find an account with that email address.';
            if (result.message && result.message.includes('hcaptcha')) {
              failedReason = 'Hcaptcha Verification is failed';
              this.props.setViewMessages([defaultMsg]);
            } else {
              this.props.setViewMessages([
                <span key="email-not-found">
                  We cannot find an account with that email address.
                </span>,
              ]);
            }
            track(segmentEnum.SendResetPasswordEmail, {
              success: false,
              failed_reason: failedReason,
            });
            this.setState({
              ...this.state,
              successMessage: null,
            });
          });
          break;

        default:
          this.setState({
            ...this.state,
            successMessage: null,
          });
          this.props.setViewMessages([defaultMsg]);
      }
    }
  }

  handleResetPasswordKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      this.handleResetPassword();
      e.preventDefault();
    }
  };

  handlePopoverClose = (e) => {
    this.setState({
      ...this.state,
      isPopoverOpen: false,
      newPasswordValidateErrorMessages:
        this.state.isValidNewPassword || e.target.value === ''
          ? []
          : ['Please input a valid password.'],
    });
  };

  handleFocusResetPassword = () => {
    this.setState({
      ...this.state,
      isPopoverOpen: true,
      passwordValidateErrorMessages: [],
      newPasswordValidateErrorMessages: [],
    });
  };

  processSuccessUpdatePassword = (_: string, response: { redirectUrl: string }[]) => {
    this.setState({ ...this.state, isUpdateSuccess: true });
    if (response && response.length > 0) {
      track(segmentEnum.AutoLoggedIn, {
        success: true,
      });
      gotoPageAfterXSecond({
        goto_url: env.envURL,
      });
    } else {
      track(segmentEnum.AutoLoggedIn, {
        success: false,
        failed_reason: 'Can not auto-logged in after updated password',
      });
    }
  };

  handleUpdatePasswordToServer = () => {
    const notEmptyPassword1 = notEmptyRegex.test(this.props.password_1);
    const notEmptyPassword2 = notEmptyRegex.test(this.props.password_2);
    const newPasswordInputValidation = this.state.newPasswordValidateErrorMessages as string[];
    const confirmPasswordInputValidation = this.state.passwordValidateErrorMessages as string[];
    let failedReasons: string[][] = [];
    if (!notEmptyPassword1 || !notEmptyPassword2) {
      if (!notEmptyPassword1) {
        newPasswordInputValidation.push('Password is required.');
      }
      if (!notEmptyPassword2) {
        confirmPasswordInputValidation.push('Type your new password again.');
      }
    } else {
      if (
        this.props.password_1 !== this.props.password_2 &&
        confirmPasswordInputValidation.length === 0
      ) {
        confirmPasswordInputValidation.push('Passwords do not match.');
      }
    }
    //Track event Password Validation
    if (newPasswordInputValidation.length > 0) {
      failedReasons.push(newPasswordInputValidation);
    }
    if (confirmPasswordInputValidation.length > 0) {
      failedReasons.push(confirmPasswordInputValidation);
    }
    this.trackValidationInputPassword(failedReasons);
    if (newPasswordInputValidation.length > 0 || confirmPasswordInputValidation.length > 0) {
      this.setState({
        ...this.state,
        newPasswordValidateErrorMessages: newPasswordInputValidation,
        passwordValidateErrorMessages: confirmPasswordInputValidation,
      });
      return;
    }

    if (confirmPasswordInputValidation.length > 0) {
      this.setState({
        ...this.state,
        passwordValidateErrorMessages: confirmPasswordInputValidation,
      });
      return;
    }

    this.setState({
      ...this.state,
      passwordValidateErrorMessages: [],
      newPasswordValidateErrorMessages: [],
    });

    const { password_1 } = this.props;
    const { resetKey, email } = this.state;
    this.setState({ ...this.state, isUpdating: true });
    updatePassword(email, password_1, resetKey).then((res) => {
      this.setState({ ...this.state, isUpdating: false });
      if (res.status) {
        switch (res.status) {
          case 200:
            this.props.setViewMessages([]);
            track(segmentEnum.CreateNewPassword, {
              success: true,
            });
            res.json().then((json: { redirectUrl: string }) => {
              this.processSuccessUpdatePassword(origin, json);
            });
            break;
          case 400:
          case 422:
            res.json().then((json: { errors: string[] }) => {
              if (json.errors) {
                this.props.setViewMessages(json.errors);
              } else {
                this.props.setViewMessages([defaultMsg]);
              }
            });
            this.props.setViewMessages([defaultMsg]);
            track(segmentEnum.CreateNewPassword, {
              success: false,
              failed_reason: 'Error message from response',
            });
            break;

          default:
            this.props.setViewMessages([defaultMsg]);
        }
      }
    });
  };

  trackValidationInputPassword(failedReasons: string[]) {
    if (!isValidMinPasswordLength(this.props.password_1)) {
      failedReasons.push('Sorry, your password must have a minimum of 8 characters.');
    }
    if (!isValidMaxPasswordLength(this.props.password_1)) {
      failedReasons.push('Sorry, your password cannot exceed 50 characters.');
    }
    if (!this.state.isContainNumber) {
      failedReasons.push('Sorry, your password must contain at least one NUMBER.');
    }
    if (!this.state.isContainSpecialCharacter) {
      failedReasons.push(
        'Sorry, your password must contain at least one SPECIAL character, e.g., ! @ # ? ].',
      );
    }
    if (!this.state.isContainLowerCase) {
      failedReasons.push('Sorry, your password must contain at least one LOWERCASE letter.');
    }
    if (!this.state.isContainUpperCase) {
      failedReasons.push('Sorry, your password must contain at least one UPPERCASE letter.');
    }
    if (failedReasons.length > 0) {
      track(segmentEnum.CreateNewPassword, {
        success: false,
        failed_reason: failedReasons,
      });
    }
  }
  handleCallbackHcaptcha = (hcaptchaToken: string) => {
    document.getElementsByClassName('update-password-page')[0].removeAttribute('style');
    document.removeEventListener('pointerdown', this.handleClickOutsideHcaptcha);
    this.setState({
      ...this.state,
      hcaptchaToken: hcaptchaToken,
    });
    this.handleSendEmail(hcaptchaToken);
  };

  checkHcaptchaVisible = () => {
    var hcaptchaContainer = document.getElementById('onetrust-consent-sdk');
    if (hcaptchaContainer && hcaptchaContainer.nextElementSibling) {
      return window.getComputedStyle(hcaptchaContainer.nextElementSibling).visibility === 'visible';
    }

    return false;
  };

  handleExpiredCallbackHcaptcha = () => {
    document.getElementsByClassName('update-password-page')[0].removeAttribute('style');
    document.removeEventListener('pointerdown', this.handleClickOutsideHcaptcha);
    this.setState({
      ...this.state,
      isResetting: false,
      isUpdating: false,
      hcaptchaToken: null,
    });
  };

  handleClickOutsideHcaptcha = (e) => {
    if (!this.state.isAddedPointer || e.target.className.includes('update-password-page')) {
      e.target.setAttribute('style', 'pointer-events:none');
      this.setState({ ...this.state, isAddedPointer: true });
    }
  };

  handleOpenCallbackHcaptcha = () => {
    document.addEventListener('pointerdown', this.handleClickOutsideHcaptcha);
    if (this.checkHcaptchaVisible()) {
      document
        .getElementsByClassName('update-password-page')[0]
        .setAttribute('style', 'pointer-events:none');
    }
  };

  render() {
    const {
      handleUpdatePassword1,
      handleUpdatePassword2,
      handleUpdatePasswordToServer,
      handleResetPassword,
      handleChangeEmail,
      handleResetPasswordKeyDown,
    } = this;

    const {
      isValidResetKey,
      isUpdating,
      isLoading,
      isResetting,
      successMessage,
      isUpdateSuccess,
      isValidCookie,
      isLooping,
      isResetPasswordRequired,
      passwordValidateErrorMessages,
      isPopoverOpen,
      isValidPasswordLength,
      isContainUpperAndLowerCase,
      isContainNumber,
      isContainSpecialCharacter,
      newPasswordValidateErrorMessages,
      isCloseSuccessMessage,
    } = this.state;

    return (
      <Fragment>
        {isValidCookie ? (
          <ValidCookieForm isLooping={isLooping} />
        ) : (
          <>
            {!isLoading && (
              <div className="update-password-page mar-a">
                {isUpdateSuccess ? (
                  <div className="update-password-success">
                    <div className="update-success-message">
                      <div className="update-success-message-body">
                        <div className="rectangle"></div>
                        <div className="check-circle">
                          <img alt="" src={checkCircle}></img>
                        </div>
                        <div className="reset-password-group">
                          <p className="reset-password-link-content">
                            Password updated successfully. You will now be redirected to the home
                            page.
                          </p>
                        </div>
                      </div>
                      <div className="redirect-icon">
                        <i className="far fa-spinner fa-spin" />
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {isValidResetKey ? (
                      <div className="update-password">
                        <p className="header">Reset Password</p>
                        <p className="form-description">Create a new password below</p>
                        <form>
                          <div
                            className={
                              newPasswordValidateErrorMessages.length > 0
                                ? 'labelpair labelpair-error'
                                : 'labelpair'
                            }
                          >
                            <label htmlFor="password" data-testid="reset-password-second-title">
                              New Password
                              <span>*</span>
                            </label>
                            <input
                              name="password_1"
                              type="password"
                              onBlur={this.handlePopoverClose.bind(this)}
                              onFocus={this.handleFocusResetPassword.bind(this)}
                              onChange={handleUpdatePassword1}
                            />
                            {newPasswordValidateErrorMessages.map((msg, index) => (
                              <div className="message" key={'message-' + index}>
                                {msg}
                              </div>
                            ))}
                          </div>
                          <div className="popoverContainer">
                            {isPopoverOpen && (
                              <div className="password-tooltip">
                                <div className="triangle-with-shadow" />
                                <div className="password-policy">
                                  <strong>Your password must have:</strong>
                                  <br />
                                  <div id="rule-ten-chars">
                                    {isValidPasswordLength ? (
                                      <div className="ion-checkmark-green" />
                                    ) : (
                                      <div className="ion-checkmark-round" />
                                    )}{' '}
                                    <span>8 to 50 characters</span>
                                  </div>
                                  <div id="rule-letter-case">
                                    {isContainUpperAndLowerCase ? (
                                      <div className="ion-checkmark-green" />
                                    ) : (
                                      <div className="ion-checkmark-round" />
                                    )}{' '}
                                    <span>Uppercase and lowercase letters</span>
                                  </div>
                                  <div id="rule-special-char">
                                    {isContainSpecialCharacter ? (
                                      <div className="ion-checkmark-green" />
                                    ) : (
                                      <div className="ion-checkmark-round" />
                                    )}{' '}
                                    <span>
                                      At least 1 special character <br /> (e.g. !@#$%^.)
                                    </span>
                                  </div>
                                  <div id="rule-number-letter">
                                    {isContainNumber ? (
                                      <div className="ion-checkmark-green" />
                                    ) : (
                                      <div className="ion-checkmark-round" />
                                    )}{' '}
                                    <span>At least 1 number</span>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div
                            className={
                              passwordValidateErrorMessages.length > 0
                                ? 'labelpair padding-top labelpair-error'
                                : 'labelpair padding-top'
                            }
                          >
                            <label htmlFor="password">
                              Re-enter new password
                              <span>*</span>
                            </label>
                            <input
                              name="password_2"
                              type="password"
                              onChange={handleUpdatePassword2}
                            />
                          </div>
                          <div className="messages">
                            {this.props.messages.map((msg, index) => (
                              <div className="message" key={'message-' + index}>
                                {msg}
                              </div>
                            ))}
                            {passwordValidateErrorMessages.map((msg, index) => (
                              <div className="message" key={'message-' + index}>
                                {msg}
                              </div>
                            ))}
                          </div>
                        </form>
                        <button
                          className="update-password-button button-font"
                          type="button"
                          onClick={handleUpdatePasswordToServer}
                          disabled={isUpdating}
                        >
                          {isUpdating ? (
                            <>
                              UPDATING...
                              <i className="far fa-spinner fa-spin" />
                            </>
                          ) : (
                            <>UPDATE PASSWORD</>
                          )}
                        </button>
                      </div>
                    ) : (
                      <div>
                        {successMessage && !isCloseSuccessMessage && (
                          <div className="success-message">
                            <div className="succes-message-body">
                              <div className="rectangle"></div>
                              <div className="check-circle">
                                <img alt="" src={checkCircle}></img>
                              </div>
                              <div className="reset-password-group">
                                <p className="reset-password-link">Reset Password Link Sent</p>
                                <p className="reset-password-link-content">
                                  Check your inbox and SPAM folder for your reset link.
                                </p>
                              </div>
                              <button
                                className="close"
                                onClick={this.handleCloseSuccessMessage}
                              ></button>
                            </div>
                          </div>
                        )}
                        <div className="reset-password">
                          <p className="header" data-testid="reset-password-title">
                            Reset Password
                          </p>
                          <p className="title title-padding" data-testid="reset-password-subtext">
                            {isResetPasswordRequired ? (
                              <>
                                As a security measure, we&apos;re requiring that you change your
                                password. Please enter your email and click the button below.
                              </>
                            ) : (
                              <>
                                To reset your password, please enter your FabFitFun email address
                                below.
                              </>
                            )}
                          </p>
                          <p
                            className="description description-padding"
                            data-testid="reset-password-description"
                          >
                            You will receive a link to create a new password via email - check your
                            Inbox and SPAM folder for the reset link. If you have any questions or
                            issues resetting your password, you may contact us{' '}
                            <a className="lost" href={customerCareLink} data-testid="here-btn">
                              here
                            </a>
                            .
                          </p>
                          <form>
                            <div
                              className={
                                this.props.messages.length > 0
                                  ? 'labelpair labelpair-error'
                                  : 'labelpair'
                              }
                            >
                              <label htmlFor="password" data-testid="reset-password-second-title">
                                Your FabFitFun Email Address
                                <span>*</span>
                              </label>
                              <input
                                name="email"
                                data-testid="reset-password-input"
                                type="email"
                                value={this.state.email}
                                onChange={handleChangeEmail}
                                onKeyDown={handleResetPasswordKeyDown}
                              />
                            </div>
                            <div className="messages">
                              {this.props.messages.map((msg, index) => (
                                <div
                                  className="message"
                                  data-testid="email-required-text"
                                  key={'message-' + index}
                                >
                                  {msg}
                                </div>
                              ))}
                            </div>
                          </form>
                          <button
                            className="reset-password-button button-font"
                            data-testid="reset-password-btn"
                            type="button"
                            disabled={isResetting || !isCloseSuccessMessage}
                            onClick={handleResetPassword}
                          >
                            {isResetting ? (
                              <>
                                RESET PASSWORD...
                                <i className="far fa-spinner fa-spin" />
                              </>
                            ) : (
                              <>RESET PASSWORD</>
                            )}
                          </button>
                          <Hcaptcha
                            sitekey={env.hcaptchaResetPasswordSitekey}
                            size="invisible"
                            onVerify={this.handleCallbackHcaptcha}
                            chalexpired-callback={this.handleExpiredCallbackHcaptcha}
                            onExpire={this.handleExpiredCallbackHcaptcha}
                            onError={this.handleExpiredCallbackHcaptcha}
                            open-callback={this.handleOpenCallbackHcaptcha}
                            id="hcaptchaWidget"
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
          </>
        )}
      </Fragment>
    );
  }
}

const mS = (state: {
  user: { password_1: string; password_2: string };
  view: { messages: string[] };
}) => ({
  password_1: state.user.password_1,
  password_2: state.user.password_2,
  messages: state.view.messages,
});

const mD = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setPassword1InResetForm,
      setPassword2InResetForm,
      setViewMessages,
    },
    dispatch,
  );

export default connect(mS, mD)(ResetPassword);
