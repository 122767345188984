// extracted by mini-css-extract-plugin
export var fffLogo = "LeftMenu_fffLogo__NYYBs";
export var globalCart = "LeftMenu_globalCart__izq4q";
export var headerMenuContainer = "LeftMenu_headerMenuContainer__WODPy";
export var hidden = "LeftMenu_hidden__XI2cn";
export var joinBtn = "LeftMenu_joinBtn__GfA52";
export var leftMenuContainer = "LeftMenu_leftMenuContainer__43eRa";
export var logoContainer = "LeftMenu_logoContainer__GqvwL";
export var mobileMenuContainer = "LeftMenu_mobileMenuContainer__IWPAS";
export var rightContainer = "LeftMenu_rightContainer__q7mAj";
export var sticky_cart = "LeftMenu_sticky_cart__nGEQg";