import { Component, Fragment, type ChangeEvent, type ReactNode } from 'react';
import Hcaptcha from '@hcaptcha/react-hcaptcha';
import { connect } from 'react-redux';
import { bindActionCreators, type Dispatch } from 'redux';
import { setViewMessages } from '../../actions/viewActions';
import { emailRegex, notEmptyRegex } from '../../utils/regex';
import ValidCookieForm from '../Home/ValidCookieForm';
import './WebOtp.css';
import {
  gotoPageAfterXSecond,
  isLoop,
  scrollTopInDesktopMode,
  validOrigin,
  validShortLink,
} from '../../utils/commons';
import { checkAuth, requestWebOtp } from '../../utils/data';
import env from '../../utils/env';
import { segmentEnum, track, trackPage } from '../../utils/segment';
import checkCircle from './img/check-circle-pro.svg';

const customerCareLink =
  'https://support.fabfitfun.com/en/articles/44-how-can-i-contact-customer-care';
const defaultMsg = (
  <span>
    Something is incorrect.{' '}
    <a className="lost" href={customerCareLink}>
      Contact our customer support
    </a>
  </span>
);

interface WebOtpProps {
  location: {
    search: string;
    autoLoginLink?: {
      email: string;
    };
  };
  messages: ReactNode[];
  setViewMessages: (messages: ReactNode[]) => void;
}

interface WebOtpState {
  isRequesting: boolean;
  otp: string | null;
  email: string;
  cta: string;
  isLoading: boolean;
  isValidCookie: boolean;
  isLooping: boolean;
  successMessage: string | null;
  isCloseSuccessMessage: boolean;
  isCloseErrorMessage: boolean;
  errorMessage: {
    title: string;
    message: string;
  } | null;
  hcaptchaToken: string | null;
  error: number;
  isAddedPointer?: boolean;
  isUpdating?: boolean;
}

class WebOtp extends Component<WebOtpProps, WebOtpState> {
  private timeout: NodeJS.Timeout | undefined;
  private timeoutSubmit: NodeJS.Timeout | undefined;

  state = {
    isRequesting: false,
    otp: null,
    email: '',
    cta: '',
    isLoading: false,
    isValidCookie: false,
    isLooping: true,
    successMessage: null,
    isCloseSuccessMessage: true,
    isCloseErrorMessage: true,
    errorMessage: null,
    hcaptchaToken: '',
    error: 0,
  };
  constructor() {
    super();
    this.timeout = undefined;
    this.timeoutSubmit = undefined;
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    clearTimeout(this.timeoutSubmit);
  }

  componentDidUpdate() {}

  getErrorMessage(error: number) {
    switch (error) {
      case 4:
        return {
          title: 'Login Link Expired',
          message:
            'The login link you tried to use has expired. Please request a new login link below.',
        };
      case 5:
        return {
          title: 'Email Address Not Found',
          message:
            'We cannot find an account with this email address. Please enter in a different email.',
        };
      default:
        return {
          title: 'Error',
          message: 'Something is incorrect.',
        };
    }
  }

  componentDidMount() {
    //Verify cookie and switch view accordingly
    checkAuth((res: Response) => {
      let newSearchUrl = new URLSearchParams(this.props.location.search);
      let email = newSearchUrl.get('email') || '';
      let cta = newSearchUrl.get('cta');
      let error = parseInt(newSearchUrl.get('error')) || 0;
      var hasValidCookie = true;
      if (!origin || !res.ok) {
        hasValidCookie = false;
      }
      let stateObj = {
        ...this.state,
        isLoading: false,
        email: email,
        cta: cta,
        error: error,
        errorMessage: this.getErrorMessage(error),
        isCloseErrorMessage: !error,
      };

      this.setState(stateObj);
      let loop = true;
      if (!(validOrigin(cta) || validShortLink(cta))) {
        cta = env.envURL;
      }

      if (hasValidCookie) {
        if (!isLoop()) {
          loop = false;
          gotoPageAfterXSecond({
            goto_url: cta,
          });
        }
      }

      if (error) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(this.handleCloseErrorMessage, 10000);
      }

      let autoLoginLink = this.props.location.autoLoginLink;
      if (autoLoginLink && autoLoginLink.email) {
        stateObj.email = autoLoginLink.email;
      }

      this.setState({
        ...stateObj,
        isValidCookie: res.ok,
        isLooping: loop,
      });

      trackPage(segmentEnum.AutoLoginLink, {
        has_valid_cookie: hasValidCookie,
      });
    });
  }

  handleCallbackHcaptcha = (hcaptchaToken: string) => {
    document.getElementsByClassName('web-otp-page')[0].removeAttribute('style');
    document.removeEventListener('pointerdown', this.handleClickOutsideHcaptcha);
    this.setState({
      ...this.state,
      hcaptchaToken: hcaptchaToken,
    });
    this.handleSendRequestOtpEmail(hcaptchaToken);
  };

  checkHcaptchaVisible = () => {
    var hcaptchaContainer = document.getElementById('onetrust-consent-sdk');
    if (hcaptchaContainer && hcaptchaContainer.nextElementSibling) {
      return window.getComputedStyle(hcaptchaContainer.nextElementSibling).visibility === 'visible';
    }

    return false;
  };

  handleExpiredCallbackHcaptcha = () => {
    document.getElementsByClassName('web-otp-page')[0].removeAttribute('style');
    document.removeEventListener('pointerdown', this.handleClickOutsideHcaptcha);
    this.setState({
      ...this.state,
      isRequesting: false,
      isUpdating: false,
      hcaptchaToken: null,
    });
  };

  handleClickOutsideHcaptcha = (e) => {
    if (!this.state.isAddedPointer || e.target.className.includes('web-otp-page')) {
      e.target.setAttribute('style', 'pointer-events:none');
      this.setState({ ...this.state, isAddedPointer: true });
    }
  };

  handleOpenCallbackHcaptcha = () => {
    document.addEventListener('pointerdown', this.handleClickOutsideHcaptcha);
    if (this.checkHcaptchaVisible()) {
      document
        .getElementsByClassName('web-otp-page')[0]
        .setAttribute('style', 'pointer-events:none');
    }
  };
  handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ ...this.state, email: e.target.value });
  };
  handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      this.handleOtp();
      e.preventDefault();
    }
  };

  handleCloseSuccessMessage = () => {
    clearTimeout(this.timeout);
    this.setState({ ...this.state, isCloseSuccessMessage: true });
  };

  handleCloseErrorMessage = () => {
    clearTimeout(this.timeout);
    this.setState({ ...this.state, isCloseErrorMessage: true });
  };

  handleClearError = () => {
    clearTimeout(this.timeout);
    this.setState({ ...this.state, error: 0 });
  };

  handleEnableSubmit = () => {
    clearTimeout(this.timeoutSubmit);
    this.setState({
      ...this.state,
      isRequesting: false,
    });
  };

  handleSendRequestOtpEmail(hcaptchaToken: string) {
    clearTimeout(this.timeoutSubmit);
    this.timeoutSubmit = setTimeout(this.handleEnableSubmit, 15000);

    document.getElementsByClassName('web-otp-page')[0].removeAttribute('style');
    if (hcaptchaToken) {
      requestWebOtp(
        {
          email: this.state.email,
          cta: this.state.cta || null,
        },
        hcaptchaToken,
      ).then((res) => {
        this.handleResponseSendEmail(res);
      });
    } else {
      requestWebOtp({ email: this.state.email, cta: this.state.cta }, null).then((res) => {
        this.handleResponseSendEmail(res);
      });
    }
  }

  handleResponseSendEmail(res: Response) {
    if (res.status) {
      switch (res.status) {
        case 200:
          this.props.setViewMessages([]);
          track(segmentEnum.AutoLoginLink, {
            success: true,
          });

          this.setState({
            ...this.state,
            isCloseSuccessMessage: false,
            successMessage: 'Link sent! Check your inbox and SPAM folder for your auto login link.',
          });
          //Scroll to Top after received message in Desktop mode
          scrollTopInDesktopMode();
          clearTimeout(this.timeout);
          this.timeout = setTimeout(this.handleCloseSuccessMessage, 10000);
          break;
        case 400:
        case 422:
          res.json().then((result) => {
            var failedReason = 'We cannot find an account with that email address.';
            if (result.message && result.message.includes('hcaptcha')) {
              failedReason = 'Hcaptcha Verification is failed';
              this.props.setViewMessages([defaultMsg]);
            } else {
              // Hack to circumvent React@18's batching strategy
              Promise.resolve().then(() => {
                this.setState({
                  ...this.state,
                  error: 5,
                  errorMessage: this.getErrorMessage(5),
                  isCloseErrorMessage: false,
                });
                clearTimeout(this.timeout);
                this.timeout = setTimeout(this.handleCloseErrorMessage, 10000);
              });
            }
            this.setState({
              ...this.state,
              successMessage: null,
            });
            track(segmentEnum.AutoLoginLink, {
              success: false,
              failed_reason: failedReason,
            });
          });
          break;

        default:
          this.setState({
            ...this.state,
            successMessage: null,
          });
          this.props.setViewMessages([defaultMsg]);
      }
    }
  }

  handleOtp = () => {
    const isEmail = emailRegex.test(this.state.email);
    const existsEmail = notEmptyRegex.test(this.state.email);
    if (!existsEmail || !isEmail) {
      if (!existsEmail) {
        this.props.setViewMessages([<span key="email-required">Email address is required.</span>]);
      } else {
        this.props.setViewMessages([
          <span key="email-incorrect">The email address you entered is incorrect.</span>,
        ]);
      }
      return;
    }
    this.setState({
      ...this.state,
      isRequesting: true,
    });
    if (window.hcaptcha) {
      window.hcaptcha.execute();
      return;
    } else {
      this.handleSendRequestOtpEmail();
    }
  };

  render() {
    const { handleOtp, handleChangeEmail, handleKeyDown } = this;

    const {
      isLoading,
      isRequesting,
      isValidCookie,
      isLooping,
      successMessage,
      isCloseSuccessMessage,
      isCloseErrorMessage,
      errorMessage,
      error,
    } = this.state;

    return (
      <Fragment>
        {isValidCookie ? (
          <ValidCookieForm isLooping={isLooping} />
        ) : (
          <>
            {!isLoading && (
              <div className="web-otp-page mar-a">
                {successMessage && !isCloseSuccessMessage && (
                  <div className="success-message">
                    <div className="succes-message-body">
                      <div className="rectangle"></div>
                      <div className="check-circle">
                        <img alt="" src={checkCircle}></img>
                      </div>
                      <div className="reset-password-group">
                        <p className="reset-password-link">Auto Login Link Sent</p>
                        <p className="reset-password-link-content">
                          Check your inbox and SPAM folder for your auto login link.
                        </p>
                      </div>
                      <button className="close" onClick={this.handleCloseSuccessMessage}></button>
                    </div>
                  </div>
                )}
                {error && !isCloseErrorMessage && (
                  <div className="success-message">
                    <div className="succes-message-body">
                      <div className="error-rectangle"></div>
                      <div className="check-circle">
                        <i className="fa-regular fa-circle-exclamation"></i>
                      </div>
                      <div className="reset-password-group">
                        <p className="reset-password-link">{errorMessage.title}</p>
                        <p className="reset-password-link-content">{errorMessage.message}</p>
                      </div>
                      <button className="close" onClick={this.handleClearError}></button>
                    </div>
                  </div>
                )}
                <div>
                  <div className="web-otp">
                    <p className="header" data-testid="otp-title">
                      Request a Login Link
                    </p>
                    <p className="title title-padding" data-testid="otp-subtext">
                      To request a login link, please enter your FabFitFun email address below.
                    </p>
                    <p className="title title-padding" data-testid="otp-subtext">
                      You will then receive an email containing your auto-login link, which once
                      clicked, will take you to your account. If you do not see it in your inbox,
                      check your spam folder. If you have any questions or issues logging in, you
                      may contact us{' '}
                      <a className="lost" href={customerCareLink} data-testid="here-btn">
                        here.
                      </a>
                    </p>
                    <form>
                      <div
                        className={
                          this.props.messages.length > 0 ? 'labelpair labelpair-error' : 'labelpair'
                        }
                      >
                        <label htmlFor="password" data-testid="otp-second-title">
                          Email Address
                          <span>*</span>
                        </label>
                        <input
                          name="email"
                          type="email"
                          value={this.state.email}
                          onChange={handleChangeEmail}
                          onKeyDown={handleKeyDown}
                        />
                      </div>
                      <div className="messages">
                        {this.props.messages.map((msg, index) => (
                          <div
                            className="message"
                            data-testid="email-required-text"
                            key={'message-' + index}
                          >
                            {msg}
                          </div>
                        ))}
                      </div>
                    </form>
                    <button
                      className="otp-button button-font"
                      data-testid="otp-btn"
                      type="button"
                      disabled={isRequesting || !isCloseSuccessMessage}
                      onClick={handleOtp}
                    >
                      {isRequesting ? (
                        <>
                          GET A LOGIN LINK...
                          <i className="far fa-spinner fa-spin" />
                        </>
                      ) : (
                        <>GET A LOGIN LINK</>
                      )}
                    </button>
                    <Hcaptcha
                      sitekey={env.hcaptchaWebMagicLinkSitekey}
                      size="invisible"
                      onVerify={this.handleCallbackHcaptcha}
                      chalexpired-callback={this.handleExpiredCallbackHcaptcha}
                      onExpire={this.handleExpiredCallbackHcaptcha}
                      onError={this.handleExpiredCallbackHcaptcha}
                      open-callback={this.handleOpenCallbackHcaptcha}
                      id="hcaptchaWidget"
                    />
                  </div>
                </div>
                )
              </div>
            )}
          </>
        )}
      </Fragment>
    );
  }
}

const mS = (state: { view: { messages: ReactNode[] } }) => ({
  messages: state.view.messages,
});

const mD = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setViewMessages,
    },
    dispatch,
  );

export default connect(mS, mD)(WebOtp);
