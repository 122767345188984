const boxEndpoint = process.env.REACT_APP_BOX_API;
const loginEndpoint = process.env.REACT_APP_LOGIN_API;
const userEndpoint = process.env.REACT_APP_USER_API;
const addOnsEndpoint = process.env.REACT_APP_ADD_ONS_API;
const imgBasePath = process.env.REACT_APP_IMG_BASE_PATH;
const cfURL = process.env.REACT_APP_CF_URL;
const envURL = process.env.REACT_APP_ENV_URL;
const originURL = process.env.REACT_APP_ORIGIN_URL;
const erosURL = process.env.REACT_APP_EROS_S3_URL;
const shopLostPwdURL = process.env.REACT_APP_SHOP_LOST_PASSWORD_URL;
const shortURL = process.env.REACT_APP_SHORT_URL;
const hcaptchaSitekey = process.env.REACT_APP_HCAPTCHAR_SITE_KEY;
const hcaptchaResetPasswordSitekey = process.env.REACT_APP_HCAPTCHAR_RESET_PASSWORD_SITE_KEY;
const hcaptchaWebMagicLinkSitekey = process.env.REACT_APP_HCAPTCHAR_WEB_MAGIC_LINK_SITE_KEY;

export default {
  box: boxEndpoint,
  user: userEndpoint,
  addOns: addOnsEndpoint,
  login: loginEndpoint,
  imgBaseUrl: imgBasePath,
  cfURL: cfURL,
  envURL: envURL,
  originURL: originURL,
  erosURL: erosURL,
  shopLostPwdURL: shopLostPwdURL,
  shortURL: shortURL,
  hcaptchaSitekey: hcaptchaSitekey,
  hcaptchaResetPasswordSitekey: hcaptchaResetPasswordSitekey,
  hcaptchaWebMagicLinkSitekey: hcaptchaWebMagicLinkSitekey,
};
