import { EnvUtils } from '@fff-web/fff-utilities';

type EnvConfig = {
  name: string;
  REACT_APP_OPTIMIZELY: string;
  REACT_APP_MOBILE_DYNAMIC_LINK_BASE_URL: string;
  REACT_APP_NAVIGATOR_URL: string;
  REACT_APP_BASE_URL: string;
  REACT_APP_TIMER_URL: string;
  REACT_APP_NEW_MEMBER_CAMPAIGN_URL: string;
  REACT_APP_NEW_MEMBER_API_URL: string;
  REACT_APP_ENVIRONMENT?: string;
  REACT_APP_LOGIN_BASE_URL: string;
  REACT_APP_BANNER_URL: string;
  REACT_APP_ADDONS_URL: string;
  REACT_APP_EDIT_URL: string;
  REACT_APP_NEW_MEMBER_API_BASE: string;
};

export const getEnv = (env?: string): EnvConfig => {
  if (
    process.env &&
    process.env.REACT_APP_OPTIMIZELY_ACE &&
    process.env.REACT_APP_MOBILE_DYNAMIC_LINK_BASE_URL &&
    process.env.REACT_APP_NAVIGATOR_URL &&
    process.env.REACT_APP_BASE_URL &&
    process.env.REACT_APP_TIMER_URL &&
    process.env.REACT_APP_NEW_MEMBER_CAMPAIGN_URL &&
    process.env.REACT_APP_NEW_MEMBER_API_URL &&
    process.env.REACT_APP_ENVIRONMENT &&
    process.env.REACT_APP_LOGIN_BASE_URL &&
    process.env.REACT_APP_BANNER_URL &&
    process.env.REACT_APP_ADDONS_URL &&
    process.env.REACT_APP_EDIT_URL &&
    process.env.REACT_APP_NEW_MEMBER_API_BASE
  ) {
    return {
      name: process.env.REACT_APP_ENVIRONMENT,
      REACT_APP_OPTIMIZELY_ACE: process.env.REACT_APP_OPTIMIZELY_ACE,
      REACT_APP_MOBILE_DYNAMIC_LINK_BASE_URL: process.env.REACT_APP_MOBILE_DYNAMIC_LINK_BASE_URL,
      REACT_APP_NAVIGATOR_URL: process.env.REACT_APP_NAVIGATOR_URL,
      REACT_APP_BASE_URL: process.env.REACT_APP_BASE_URL,
      REACT_APP_TIMER_URL: process.env.REACT_APP_TIMER_URL,
      REACT_APP_NEW_MEMBER_CAMPAIGN_URL: process.env.REACT_APP_NEW_MEMBER_CAMPAIGN_URL,
      REACT_APP_NEW_MEMBER_API_URL: process.env.REACT_APP_NEW_MEMBER_API_URL,
      REACT_APP_ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
      REACT_APP_LOGIN_BASE_URL: process.env.REACT_APP_LOGIN_BASE_URL,
      REACT_APP_BANNER_URL: process.env.REACT_APP_BANNER_URL,
      REACT_APP_ADDONS_URL: process.env.REACT_APP_ADDONS_URL,
      REACT_APP_EDIT_URL: process.env.REACT_APP_EDIT_URL,
      REACT_APP_NEW_MEMBER_API_BASE: process.env.REACT_APP_NEW_MEMBER_API_BASE,
    };
  }

  const enF = env || EnvUtils.getEnvUsingWindowLocation();

  if (enF === 'local') {
    return { ...getEnv('dev'), name: 'local' };
  }

  if (enF === 'prod') {
    return {
      name: 'prod',
      REACT_APP_OPTIMIZELY_ACE: 'MRGJTqny6nXVLVsM7LZ7u',
      REACT_APP_MOBILE_DYNAMIC_LINK_BASE_URL: 'https://fabfitfun.page.link',
      REACT_APP_NAVIGATOR_URL: 'https://universal-navigation-api.fabfitfun.com',
      REACT_APP_BASE_URL: 'https://fabfitfun.com',
      REACT_APP_TIMER_URL: 'https://universal-navigation-api.fabfitfun.com/timer',
      REACT_APP_NEW_MEMBER_CAMPAIGN_URL:
        'https://customize-api.fabfitfun.com/customize/new-members/self/campaign-state',
      REACT_APP_NEW_MEMBER_API_URL: 'https://new-member-api.fabfitfun.com',
      REACT_APP_BANNER_URL: 'https://homepage-api.fabfitfun.com/megaphone-banners/current',
      REACT_APP_ADDONS_URL: 'https://fabfitfun.com/add-ons',
      REACT_APP_EDIT_URL: 'https://fabfitfun.com/edit',
      REACT_APP_NEW_MEMBER_API_BASE: 'https://new-member-api.fabfitfun.com',
      REACT_APP_LOGIN_BASE_URL: 'https://login.fabfitfun.com',
    };
  }

  return {
    name: enF,
    REACT_APP_OPTIMIZELY_ACE: 'NbWDYMSCwJeD5ZMu8Ne5L',
    REACT_APP_MOBILE_DYNAMIC_LINK_BASE_URL: 'https://fffdev.page.link',
    REACT_APP_NAVIGATOR_URL: `https://universal-navigation-api.fff-${enF}.com`,
    REACT_APP_BASE_URL: `https://fff-${enF}.com`,
    REACT_APP_TIMER_URL: `https://universal-navigation-api.fff-${enF}.com/timer`,
    REACT_APP_NEW_MEMBER_CAMPAIGN_URL: `https://customize-api.fff-${enF}.com/customize/new-members/self/campaign-state`,
    REACT_APP_NEW_MEMBER_API_URL: `https://new-member-api.fff-${enF}.com`,
    REACT_APP_BANNER_URL: `https://homepage-api.fff-${enF}.com/megaphone-banners/current`,
    REACT_APP_ADDONS_URL: `https://fff-${enF}.com/add-ons`,
    REACT_APP_EDIT_URL: `https://fff-${enF}.com/edit`,
    REACT_APP_NEW_MEMBER_API_BASE: `https://new-member-api.fff-${enF}.com`,
    REACT_APP_LOGIN_BASE_URL: 'https://login.fff-dev.com',
  };
};
