export const MENU_LABELS = {
  MEMBERSHIP: 'membership',
  SHOP: 'shop',
  QUIZ: 'take the quiz',
  GIFT: 'gift',
  LOGIN: 'login',
  GET_STARTED: 'get started',
};

export const ROUTES = {
  GIFT: '/gift',
  MEMBERSHIP: '/get-the-box#how-it-works-section',
  SHOP: '/shop/?utm_source=gtb',
  QUIZ: '/get-the-box/quiz/',
  GET_STARTED: '/get-the-box/?utm_source=internal_login',
};
