import { useUserContext } from '@fff-web/fff-utilities';

export const useSegmentTrackingData = () => {
  const { userInfo } = useUserContext();

  return {
    useId: userInfo?.user?.userId,
    sessionCreationDate: userInfo?.user?.subscriptionStartDate,
  };
};
