import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, useLocation, withRouter } from 'react-router-dom';
import { bindActionCreators, type Dispatch } from 'redux';
import { GlobalFooter } from '@fff-web/fff-global-footer';
import { ProspectGlobalNav } from '@fff-web/fff-global-nav';
import { setViewWidth } from './actions/viewActions';
import './App.css';
import Home from './Components/Home/Home';
import Logout from './Components/Logout/Logout';
import Otp from './Components/Otp/Otp';
import WebOtp from './Components/Otp/WebOtp';
import WebOtpVerify from './Components/Otp/WebOtpVerify';
import ResetPassword from './Components/ResetPassword/ResetPassword';
import './globals.css';
import './Shared/fontAwesome/css/fontawesome.min.css';
import './Shared/fontAwesome/css/light.min.css';
import './Shared/fontAwesome/css/regular.min.css';
import './Shared/fontAwesome/css/solid.min.css';
import env from './utils/env';

type AppProps = {
  setViewWidth: (width: number) => void;
};

const App = ({ setViewWidth }: AppProps) => {
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => setViewWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [setViewWidth]);

  const handleCTA = () => {
    window.location.href = `${env.envURL}/get-the-box/?utm_source=internal_login`;
  };

  const isLogoutPage = ['/logout'].indexOf(location.pathname) === 0;

  return (
    <div className="App">
      {!isLogoutPage && <ProspectGlobalNav handleCTA={handleCTA} isLoginPage />}
      <main className="add-ons">
        <Route path="/lost-password" component={ResetPassword} />
        <Route path="/otp" component={Otp} />
        <Route path="/web-otp" component={WebOtp} />
        <Route path="/web-otp-verify" component={WebOtpVerify} />
        <Route path="/" exact component={Home} />
        <Route path="/logout" exact component={Logout} />
      </main>
      {!isLogoutPage && <GlobalFooter isUseAnalytic={false} />}
    </div>
  );
};

const mS = (state: { view: { isMobile: boolean } }) => ({
  isMobile: state.view.isMobile,
});

const mD = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setViewWidth,
    },
    dispatch,
  );

export default withRouter(connect(mS, mD)(App));
