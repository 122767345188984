import OptimizelyProvider from '../../context/OptimizelyProvider';
import { GROWTH_GLOBAL_NAV } from '../../utils/featureFlagVariants/growth-global-nav';
import { Banner } from '../Banners/Banner';
import MobileWebBanner from '../MobileWebBanner';
import NavToast from '../Toast/NavToast';
import { ProspectNavBar } from './components/ProspectNavBar/ProspectNavBar';

type Props = {
  variationKey?: string;
  isPublicShop: boolean;
  showCart: boolean;
  stickyCartPage: boolean;
  isLoginPage: boolean;
};

const ProspectGlobalNav = ({
  variationKey = GROWTH_GLOBAL_NAV.VARIATION_D,
  isPublicShop,
  showCart,
  stickyCartPage,
  isLoginPage,
}: Props) => {
  return (
    <OptimizelyProvider>
      <Banner isPublicShop={isPublicShop} />
      <ProspectNavBar
        variationKey={variationKey}
        showCart={showCart}
        stickyCartPage={stickyCartPage}
        isPublicShop={isPublicShop}
        isLoginPage={isLoginPage}
      />
      <MobileWebBanner />
      <NavToast loggedIn={false} />
    </OptimizelyProvider>
  );
};

export default ProspectGlobalNav;
