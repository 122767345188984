import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, type Dispatch } from 'redux';
import ValidCookieForm from '../Home/ValidCookieForm';
import './Otp.css';
import { gotoPageAfterXSecond, isLoop, validOrigin, validShortLink } from '../../utils/commons';
import { checkAuth, processOtp } from '../../utils/data';
import env from '../../utils/env';

interface OtpProps {
  location: {
    search: string;
  };
}

interface OtpState {
  isLoggingIn: boolean;
  otp: string | null;
  email: string;
  cta: string;
  isLoading: boolean;
  isValidCookie: boolean;
  isLooping: boolean;
  isCloseSuccessMessage: boolean;
}

class Otp extends Component<OtpProps, OtpState> {
  private timeout: NodeJS.Timeout | undefined;

  state = {
    isLoggingIn: false,
    otp: null,
    email: '',
    cta: '',
    isLoading: true,
    isValidCookie: false,
    isLooping: true,
    isCloseSuccessMessage: true,
  };

  constructor() {
    super();
    this.timeout = undefined;
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  componentDidMount() {
    //Verify cookie and switch view accordingly
    checkAuth((res: Response) => {
      let newSearchUrl = new URLSearchParams(this.props.location.search);
      let email = newSearchUrl.get('email');
      let otp = newSearchUrl.get('otp');
      let cta = newSearchUrl.get('cta');
      setTimeout(() => {
        this.setState({
          ...this.state,
          isLoading: false,
          email: email || '',
          otp: otp || '',
          cta: cta || '',
        });
      });
      let loop = true;
      if (!(validOrigin(cta) || validShortLink(cta))) {
        cta = env.envURL;
      }

      if (res.ok) {
        if (!isLoop()) {
          loop = false;
          gotoPageAfterXSecond({
            goto_url: cta,
          });
        }
      }

      this.setState({
        ...this.state,
        isValidCookie: res.ok,
        isLooping: loop,
      });
    });
  }

  handleOtp = () => {
    this.setState({
      ...this.state,
      isLoggingIn: true,
    });

    processOtp(this.state.email, this.state.otp, this.state.cta).then((res) => {
      this.setState({ ...this.state, isLoggingIn: false });
      if (res.status) {
        switch (res.status) {
          case 200:
            res.json().then((json: { redirectUrl: string }) => {
              gotoPageAfterXSecond({
                goto_url: json.redirectUrl,
              });
            });
            break;
          case 400:
          case 422:
            gotoPageAfterXSecond({
              goto_url: env.envURL,
            });
            break;
          default:
            gotoPageAfterXSecond({
              goto_url: env.envURL,
            });
        }
      }
    });
  };

  render() {
    const { handleOtp } = this;

    const { isLoading, isLoggingIn, isValidCookie, isLooping, isCloseSuccessMessage } = this.state;

    return (
      <Fragment>
        {isValidCookie ? (
          <ValidCookieForm isLooping={isLooping} />
        ) : (
          <>
            {!isLoading && (
              <div className="otp-page mar-a">
                <div>
                  <div className="otp">
                    <p className="header" data-testid="otp-title">
                      Member Login
                    </p>
                    <p className="title title-padding" data-testid="otp-subtext"></p>
                    <form>
                      <div className="labelpair">
                        <label htmlFor="password" data-testid="otp-second-title">
                          Email Address
                          <span>*</span>
                        </label>
                        <input name="email" type="text" value={this.state.email} disabled />
                        <input
                          name="email"
                          data-testid="otp-input"
                          type="hidden"
                          value={this.state.email}
                        />
                      </div>
                    </form>
                    <button
                      className="otp-button button-font"
                      data-testid="otp-btn"
                      type="button"
                      disabled={isLoggingIn || !isCloseSuccessMessage}
                      onClick={handleOtp}
                    >
                      {isLoggingIn ? (
                        <>
                          LOGIN...
                          <i className="far fa-spinner fa-spin" />
                        </>
                      ) : (
                        <>LOGIN</>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </Fragment>
    );
  }
}

const mD = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect(mD)(Otp);
