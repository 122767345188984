import classNames from 'classnames';
import ProspectBanner from '../ProspectBanner/ProspectBanner';
import { LeftMenu } from './components/LeftMenu/LeftMenu';
import { MainMenu } from './components/MainMenu/MainMenu';
import { RightMenu } from './components/RightMenu/RightMenu';
import { isInvertedGTB } from './utils';
import * as styles from './ProspectNavBar.module.scss';

type Props = {
  variationKey: string;
  showCart: boolean;
  stickyCartPage: boolean;
  isLoginPage: boolean;
  isPublicShop: boolean;
};

export const ProspectNavBar = ({
  variationKey,
  showCart,
  stickyCartPage,
  isLoginPage = false,
  isPublicShop = false,
}: Props) => {
  const isInvertedGTBPage = isInvertedGTB();
  const showProspectBanner = !isInvertedGTBPage && !isLoginPage;

  return (
    <>
      <header
        className={classNames(styles.prospectNavBarContainer, {
          [styles.prospectNavBarContainerShop]: isPublicShop,
        })}
      >
        <LeftMenu
          showCart={showCart}
          stickyCartPage={stickyCartPage}
          isLoginPage={isLoginPage}
          isPublicShop={isPublicShop}
        />
        <MainMenu
          variationKey={variationKey}
          isLoginPage={isLoginPage}
          isPublicShop={isPublicShop}
        />
        <RightMenu
          showCart={showCart}
          stickyCartPage={stickyCartPage}
          isLoginPage={isLoginPage}
          isPublicShop={isPublicShop}
        />
      </header>
      {showProspectBanner && (
        <ProspectBanner variationKey={variationKey} isPublicShop={isPublicShop} />
      )}
    </>
  );
};
