import { GROWTH_GLOBAL_NAV } from '../../../../../../utils/featureFlagVariants/growth-global-nav';
import { MENU_LABELS, ROUTES } from '../../constants';
import { isInvertedGTB } from '../../utils';
import { NavLink } from '../NavLink/NavLink';
import * as styles from './MainMenu.module.scss';

type Props = {
  variationKey: string;
  isLoginPage: boolean;
  isPublicShop: boolean;
};

export const MainMenu = ({ variationKey, isLoginPage, isPublicShop }: Props) => {
  const isInvertedGTBPage = isInvertedGTB();

  const routes = [
    {
      name: MENU_LABELS.MEMBERSHIP,
      url: ROUTES.MEMBERSHIP,
      enabled: !(isInvertedGTBPage || isLoginPage),
    },
    {
      name: MENU_LABELS.SHOP,
      url: ROUTES.SHOP,
      enabled: GROWTH_GLOBAL_NAV.VARIATION_C === variationKey || isPublicShop,
    },
    {
      name: MENU_LABELS.QUIZ,
      url: ROUTES.QUIZ,
      enabled: GROWTH_GLOBAL_NAV.VARIATION_E === variationKey && !isInvertedGTBPage,
    },
  ];

  const links = routes.filter((link) => link.enabled);

  return (
    <div className={styles.mainMenuContainer} data-testid="main-menu">
      {links.map((link, idx) => (
        <NavLink key={idx} {...link} />
      ))}
    </div>
  );
};
