import { Cookies } from '@fff-web/fff-utilities';
import { UAParser } from 'ua-parser-js';
import { identify, trackEvent, trackPageLoad, trackElement } from '@fff-web/fff-analytics';

export const segmentEnum = {
  LoggedIn: 'Logged In',
  CreateNewPassword: 'Create New Password',
  SendResetPasswordEmail: 'Send Reset Password Email',
  AutoLoggedIn: 'Auto-logged In Updating Password',
  AutoLoginLink: 'Login',
};

/**
 * Track an event
 * @param {string} eventName - Event name
 * @param {object} trackingData - Tracking data
 */
export function track(eventName, trackingData) {
  try {
    trackEvent({ ctaName: eventName, properties: trackingData });
  } catch (e) {
    console.log(e);
  }
}

/**
 * Get data from params
 * @param {object} params - Params
 * @returns {object} Data
 */
function getData(params) {
  var data = {};
  if (typeof params !== 'undefined' && Object.prototype.hasOwnProperty.call(params, 'data')) {
    data = params['data'];
  }
  return data;
}

/**
 * Track a clicked element
 * @param {object} params - Params
 */
export function clickedElement(params) {
  try {
    var dataParams = getData(params);
    if (
      typeof params != 'undefined' &&
      Object.prototype.hasOwnProperty.call(params, 'target_element')
    ) {
      trackElement({ elementName: params['target_element'], properties: dataParams });
    } else {
      trackElement({ elementName: 'Clicked Element', properties: dataParams });
    }
  } catch (e) {
    console.log(e);
  }
}

/*
 *Track user's identify
 */
/**
 * Identify a user
 * @param {string} userId - User ID
 * @param {object} traits - Traits
 * @param {boolean} forceUpdate - Force update
 */
export function identifyUser(userId, traits, forceUpdate = false) {
  try {
    if (forceUpdate) {
      identify({ userId, traits });
      return;
    }
    var analyticsCookieValue = Cookies.getCookie('ajs_user_id');
    if (
      (analyticsCookieValue === '' ||
        analyticsCookieValue === 'null' ||
        analyticsCookieValue === '0' ||
        analyticsCookieValue !== userId) &&
      typeof traits != 'undefined' &&
      userId > 0
    ) {
      var contexts = getSegmentContextInfo();
      identify({ userId, traits, contexts });
    }
  } catch (e) {
    console.log(e);
  }
}

/**
 * Track a page
 * @param {string} name - Name
 * @param {object} properties - Properties
 */
export function trackPage(name, properties) {
  properties = properties || {};
  trackPageLoad({ pathName: name, properties: properties });
}

/**
 * Get segment context info
 * @returns {object} Context info
 */
function getSegmentContextInfo() {
  var parser = new UAParser();
  var result = parser.getResult();
  var browser = {
    name: result.browser.name,
    version: result.browser.version,
  };
  var screenSize = {
    width: window.screen.width,
    height: window.screen.height,
  };
  var contexts = {
    os: result.os,
    browser: browser,
    screen: screenSize,
  };
  return contexts;
}
