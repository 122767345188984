import { type MouseEvent } from 'react';
import { getEnv } from '../../../../../../../environment';
import { constructUrlWithParams } from '../../../../../../utils/navigation';
import { createTrackingData } from '../../../../../../utils/prospectGlobalNav';
import { useSegmentTrackingData } from '../../hooks';
import * as styles from './NavLink.module.scss';

type Props = {
  url: string;
  name: string;
  enabled: boolean;
  isLogin?: boolean;
  style?: string;
};

export const NavLink = ({ url, name, enabled, isLogin, style }: Props) => {
  const segmentTrackingData = useSegmentTrackingData();
  const env = getEnv();
  const baseUrl = env.REACT_APP_BASE_URL;
  const linkUrl = `${baseUrl}${url}`;

  const handleClick = async (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    if (isLogin) {
      window.location.href = url;
      return;
    }

    await createTrackingData({
      ctaName: name,
      segmentTrackingData,
    });

    const newURL = constructUrlWithParams(linkUrl);

    window.location.href = newURL;
  };

  if (!enabled) {
    return null;
  }

  return (
    <a className={style || styles.navLink} href={url} onClick={handleClick}>
      {name}
    </a>
  );
};
