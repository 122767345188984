/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Component, Fragment, type ChangeEvent, type KeyboardEventHandler } from 'react';
import Hcaptcha from '@hcaptcha/react-hcaptcha';
import { Cookies } from '@fff-web/fff-utilities';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators, type Dispatch } from 'redux';
import { setPassword, setUserData, setUsername } from '../../actions/userActions';
import { setViewMessages, showPrompt } from '../../actions/viewActions';
import { gotoPageAfterXSecond, isLoop, validOrigin, validShortLink } from '../../utils/commons';
import {
  checkAuth,
  checkWhitelistIpAddress,
  generateCookieHcaptcha,
  getOriginParam,
  getUserData,
} from '../../utils/data';
import env from '../../utils/env';
import ValidCookieForm from './ValidCookieForm';

import { emailRegex, notEmptyRegex } from '../../utils/regex';
import { clickedElement, identifyUser, segmentEnum, track, trackPage } from '../../utils/segment';
import './Home.css';

const defaultMsg = (
  <span>
    The email or password you entered is incorrect.{' '}
    <Link to={{ pathname: `/lost-password` }} data-testid="lost-your-password-btn">
      Lost your password?
    </Link>
  </span>
);

const TERMS_OF_USE_AND_SALE = 'https://legal.fabfitfun.com/#terms-of-use';
const FABFITFUN_MEMBERSHIP_TERMS = 'https://legal.fabfitfun.com/#box-membership-terms';

interface HomeProps {
  username: string;
  password: string;
  user: Record<string, string>;
  isMobile: boolean;
  messages: string[];
  promptMessage?: string;
  setUserData: typeof setUserData;
  setUsername: typeof setUsername;
  setPassword: typeof setPassword;
  setViewMessages: typeof setViewMessages;
  showPrompt: typeof showPrompt;
}

interface HomeState {
  loggingIn: boolean;
  passwordReset: boolean;
  isValidCookie: boolean;
  isLooping: boolean;
  hcaptchaToken: string | null;
  disableHcaptcha: boolean;
  emailAddressValidationErrorMessage: string[];
  passwordValidationErrorMessages: string[];
  widgetHcaptchaId: string | null;
  isAddedPointer: boolean;
  showPassword: boolean;
}

class Home extends Component<HomeProps, HomeState> {
  state = {
    loggingIn: false,
    passwordReset: Cookies.getCookie('pwr') === '1',
    isValidCookie: false,
    isLooping: true,
    hcaptchaToken: '',
    disableHcaptcha: false,
    emailAddressValidationErrorMessage: [],
    passwordValidationErrorMessages: [],
    widgetHcaptchaId: null,
    isAddedPointer: false,
    showPassword: false,
  };

  componentDidMount() {
    this.props.setViewMessages([]);
    this.props.setUsername('');
    this.props.setPassword('');
    //Verify cookie and switch view accordingly
    checkAuth((res: Response) => {
      let isValidCookie = true;
      let loop = true;
      if (!origin || !res.ok) {
        isValidCookie = false;
      }
      //Add overlay model for body
      if (isValidCookie) {
        let gotoUrl = getOriginParam();
        if (!(validOrigin(gotoUrl) || validShortLink(gotoUrl))) {
          gotoUrl = env.envURL;
        }
        if (!isLoop()) {
          loop = false;
          gotoPageAfterXSecond({
            goto_url: gotoUrl,
          });
        }
        document.body.classList.add('modal-open');
      } else {
        document.body.classList.remove('modal-open');
      }
      this.setState({
        ...this.state,
        isValidCookie: isValidCookie,
        isLooping: loop,
      });
      trackPage('Login', { has_valid_cookie: isValidCookie });
    });
    //bind track event to the default forgot password work link
    //the event will be fired when user click the link
    clickedElement({
      target_element: document.getElementsByClassName('lost'),
      data: {
        element_name: 'forgot your password',
        password_reset: this.state.passwordReset,
      },
    });

    checkWhitelistIpAddress((res: Response) => {
      if (res.status === 200) {
        res.json().then((json) => {
          this.setState({
            ...this.state,
            disableHcaptcha: json,
          });
        });
      }
    });
  }

  handleUpdateUsername = (e: ChangeEvent<HTMLInputElement>) => {
    this.props.setUsername(e.target.value);
    this.setState({ ...this.state, emailAddressValidationErrorMessage: [] });
  };

  handleUpdatePassword = (e: ChangeEvent<HTMLInputElement>) => {
    this.props.setPassword(e.target.value);
    this.setState({ ...this.state, passwordValidationErrorMessages: [] });
  };

  isPasswordChangeRequired = (res?: { message: string }) => {
    return res && res.message === 'Password change required';
  };

  isInvalidHcaptcha = (res?: { message: string }) => {
    return res && res.message.includes('hcaptcha');
  };

  processSuccessLogin = (origin: string) => {
    //call user self end point for getting logged in user info
    let passwordReset = this.state.passwordReset;
    let isSocial = false;
    getUserData().then((userRes) => {
      try {
        if (userRes.status === 200) {
          userRes.json().then((userJson: { user: Record<string, string> }) => {
            //Set user identify, all sub tracking would be associated with this user.
            identifyUser(userJson.user['wooId'], {});

            //Track logged in event
            track(segmentEnum.LoggedIn, {
              success: true,
              password_reset: passwordReset,
            });
          });
        } else {
          //Track logged in event
          //This case should not happen but
          //put the tracking here for just in case
          //user service being down right this time
          track(segmentEnum.LoggedIn, {
            success: true,
            password_reset: passwordReset,
          });
        }
      } catch (e) {
        //bypass any error for segment tracking
        console.log(e);
      }
      Cookies.deleteCookie('pwr');

      if (isSocial && origin === env.envURL) {
        gotoPageAfterXSecond({
          goto_url: origin + '/edit',
        });
      } else {
        //go to origin url after login successfully
        gotoPageAfterXSecond({
          goto_url: origin,
        });
      }
    });
  };

  handleFocusEmailAddress = () => {
    this.props.setViewMessages([]);
  };

  handleLogin = () => {
    // Validation
    const isEmail = emailRegex.test(this.props.username);
    const isPassword = notEmptyRegex.test(this.props.password);
    const existsEmail = notEmptyRegex.test(this.props.username);
    const inputEmailAddressValidation = [];
    const inputPasswordValidation = [];
    this.props.setViewMessages([]);
    if (!existsEmail) {
      inputEmailAddressValidation.push('Email address is required.');
    }
    if (!isPassword) {
      inputPasswordValidation.push('Password is required.');
    }
    if (!existsEmail || !isPassword) {
      this.setState({
        ...this.state,
        emailAddressValidationErrorMessage: inputEmailAddressValidation,
        passwordValidationErrorMessages: inputPasswordValidation,
      });
      return;
    }
    if (!isEmail) {
      this.setState({
        ...this.state,
        emailAddressValidationErrorMessage: ['The email address you entered is incorrect.'],
      });
      return;
    }

    //check Hcaptcha feature flag
    if (this.state.disableHcaptcha) {
      const { username, password } = this.props;
      generateCookieHcaptcha(username, password, 'hcaptchaToken').then((res) => {
        if (res.status) {
          this.handleResponseGeneratedCookie(res);
        }
      });
    } else {
      if (window.hcaptcha) {
        this.setState({ ...this.state, loggingIn: true });
        window.hcaptcha.execute();
      } else {
        this.fetchCookie();
      }
    }
  };

  fetchCookie = (hcaptchaToken?: string) => {
    document.getElementsByClassName('login-page')[0].removeAttribute('style');
    const { username, password } = this.props;
    this.setState({ ...this.state, loggingIn: true });
    if (window.hcaptcha && hcaptchaToken) {
      generateCookieHcaptcha(username, password, hcaptchaToken).then((res) => {
        if (res.status) {
          this.handleResponseGeneratedCookie(res);
        }
      });
    }
  };

  _handleKeyPress: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key === 'Enter') {
      this.handleLogin();
    }
  };

  handleCallbackHcaptcha = (hcaptchaToken: string) => {
    document.getElementsByClassName('login-page')[0].removeAttribute('style');
    document.removeEventListener('pointerdown', this.handleClickOutsideHcaptcha);
    this.setState({
      ...this.state,
      hcaptchaToken: hcaptchaToken,
    });
    this.fetchCookie(hcaptchaToken);
  };

  checkHcaptchaVisible = () => {
    var hcaptchaContainer = document.getElementById('onetrust-consent-sdk');
    if (hcaptchaContainer && hcaptchaContainer.nextElementSibling) {
      return window.getComputedStyle(hcaptchaContainer.nextElementSibling).visibility === 'visible';
    }

    return false;
  };

  handleExpiredCallbackHcaptcha = () => {
    document.getElementsByClassName('login-page')[0].removeAttribute('style');
    document.removeEventListener('pointerdown', this.handleClickOutsideHcaptcha);
    this.setState({
      ...this.state,
      loggingIn: false,
      hcaptchaToken: null,
    });
  };

  handleShowPasswordToggle = () => {
    this.setState({ ...this.state, showPassword: !this.state.showPassword });
  };

  handleClickOutsideHcaptcha = (e) => {
    if (!this.state.isAddedPointer || e.target.className.includes('login-page')) {
      e.target.setAttribute('style', 'pointer-events:none');
      this.setState({ ...this.state, isAddedPointer: true });
    }
  };

  handleOpenCallbackHcaptcha = () => {
    document.addEventListener('pointerdown', this.handleClickOutsideHcaptcha);
    if (this.checkHcaptchaVisible()) {
      document.getElementsByClassName('login-page')[0].setAttribute('style', 'pointer-events:none');
    }
  };

  handleResponseGeneratedCookie(res: Response) {
    const origin = getOriginParam();
    switch (res.status) {
      case 401:
        res.json().then((json) => {
          let isPasswordChangeRequired = this.isPasswordChangeRequired(json);

          if (isPasswordChangeRequired) {
            this.props.setViewMessages([]);
            clickedElement({
              target_element: document.getElementsByClassName('lost'),
              data: {
                element_name: 'forgot your password',
                password_reset: this.state.passwordReset,
              },
            });
            gotoPageAfterXSecond({
              goto_url: '/lost-password?isResetPasswordRequired=true&email=' + this.props.username,
            });
          } else {
            this.setState({ ...this.state, loggingIn: false });
            this.props.setViewMessages([defaultMsg]);
            this.props.showPrompt(undefined);
            clickedElement({
              target_element: document.getElementsByClassName('lost'),
              data: {
                element_name: 'forgot your password',
                password_reset: this.state.passwordReset,
              },
            });
          }

          //Track login fails
          track(segmentEnum.LoggedIn, {
            success: false,
            failed_reason: isPasswordChangeRequired
              ? 'password change required'
              : 'incorrect credentials',
            password_reset: this.state.passwordReset,
          });
        });
        break;

      case 400:
        res.json().then((result) => {
          if (this.isInvalidHcaptcha(result)) {
            this.setState({ ...this.state, loggingIn: false });
            this.props.setViewMessages(['Login failed. Please try again.']);
          } else {
            //Track login fails
            this.setState({ ...this.state, loggingIn: false });
            track(segmentEnum.LoggedIn, {
              success: false,
              failed_reason: 'bad request parameter',
              password_reset: this.state.passwordReset,
            });

            checkAuth((res: Response) => {
              if (!origin || !res.ok) {
                // Message if origin param is missing
                this.props.setViewMessages([
                  <span key="error-message">
                    Sorry, there was an error logging you in. Please{' '}
                    <Link to={{ pathname: `/lost-password` }}>click here </Link> to reset your
                    password or reach out to{' '}
                    <a href="https://fabfitfun.com/concierge/">Customer Care.</a>
                  </span>,
                ]);
                //need to rebind after adding message object
                clickedElement({
                  target_element: document.getElementsByClassName('lost'),
                  data: {
                    element_name: 'forgot your password',
                    password_reset: this.state.passwordReset,
                  },
                });
              } else {
                window.location.href = env.envURL;
              }
            });
          }
        });
        break;

      case 200:
        this.processSuccessLogin(origin);
        break;

      default:
        this.setState({ ...this.state, loggingIn: false });
        this.props.setViewMessages([defaultMsg]);
        track(segmentEnum.LoggedIn, {
          success: false,
          failed_reason: 'internal error',
          password_reset: this.state.passwordReset,
        });

        clickedElement({
          target_element: document.getElementsByClassName('lost'),
          data: {
            element_name: 'forgot your password',
            password_reset: this.state.passwordReset,
          },
        });
    }
  }

  render() {
    const {
      _handleKeyPress,
      handleUpdateUsername,
      handleUpdatePassword,
      handleLogin,
      handleFocusEmailAddress,
    } = this;

    const { emailAddressValidationErrorMessage, passwordValidationErrorMessages } = this.state;

    return (
      <Fragment>
        {this.state.isValidCookie ? (
          <ValidCookieForm isLooping={this.state.isLooping} />
        ) : (
          <div className="login-page mar-a" onKeyDown={_handleKeyPress}>
            <div className="login">
              <div className="member-login">
                <div className="member-login-content" data-testid="login-title">
                  Member Login
                </div>
              </div>
              <form>
                {this.props.promptMessage ? <div>{this.props.promptMessage}</div> : ''}
                <div
                  className={
                    emailAddressValidationErrorMessage.length > 0
                      ? 'labelpair-login labelpair-login-error'
                      : 'labelpair-login'
                  }
                >
                  <label htmlFor="username" data-testid="email-address-text">
                    Email Address
                    <span>*</span>
                  </label>
                  <input
                    name="username"
                    data-testid="email-address-input"
                    onChange={handleUpdateUsername}
                    onFocus={handleFocusEmailAddress.bind(this)}
                    aria-label="username"
                    data-e2e="username"
                  />
                  {emailAddressValidationErrorMessage.map((msg, index) => (
                    <div
                      className="message"
                      key={'message-' + index}
                      data-testid="email-required-text"
                    >
                      {msg}
                    </div>
                  ))}
                </div>
                <div
                  className={
                    passwordValidationErrorMessages.length > 0
                      ? 'labelpair-login labelpair-login-error'
                      : 'labelpair-login'
                  }
                >
                  <label htmlFor="password" data-testid="password-text">
                    Password
                    <span>*</span>
                  </label>
                  <div>
                    <input
                      name="password"
                      data-testid="password-input"
                      type={this.state.showPassword ? 'text' : 'password'}
                      onChange={handleUpdatePassword}
                      aria-label="password"
                      data-e2e="password"
                    />
                    {/* TODO: Please fix the a11y issues with this element and then remove the comments at the top of the file. */}
                    <span
                      className={
                        this.state.showPassword
                          ? 'far fa-eye-slash field-icon toggle-password'
                          : 'far fa-eye field-icon toggle-password'
                      }
                      onClick={this.handleShowPasswordToggle}
                    ></span>
                  </div>
                  {passwordValidationErrorMessages.map((msg, index) => (
                    <div
                      className="message"
                      key={'message-' + index}
                      data-testid="password-required-text"
                    >
                      {msg}
                    </div>
                  ))}
                </div>
                <div className="messages">
                  {this.props.messages.map((msg, index) => (
                    <div
                      className="message"
                      key={'message-' + index}
                      data-testid="password-incorrect-text"
                    >
                      {msg}
                    </div>
                  ))}
                </div>
                <div className="disclaimer" data-testid="disclaimer-text">
                  By logging in, you agree to our{' '}
                  <a href={TERMS_OF_USE_AND_SALE} data-testid="terms-of-use-btn">
                    <span>Terms of Use and Sale</span>
                  </a>{' '}
                  and{' '}
                  <a href={FABFITFUN_MEMBERSHIP_TERMS} data-testid="membership-terms-btn">
                    <span>FabFitFun Membership Terms</span>
                  </a>
                  .
                </div>
                {this.state.loggingIn ? (
                  <button className="login-page-button" type="button" disabled>
                    Logging In &nbsp;
                    <i className="far fa-spinner fa-spin" />
                  </button>
                ) : (
                  <button
                    className="login-page-button"
                    data-testid="login-btn"
                    data-e2e="login-btn"
                    onClick={handleLogin}
                    type="button"
                  >
                    Log In
                  </button>
                )}
              </form>
              <div>
                <div className="links-login">
                  <Link
                    to={{
                      pathname: `/web-otp`,
                      autoLoginLink: { email: this.props.username },
                    }}
                    data-testid="forgot-password-btn"
                  >
                    Request Login Link
                  </Link>
                  &nbsp; | &nbsp;
                  <Link
                    to={{
                      pathname: `/lost-password`,
                      forgotPassword: { email: this.props.username },
                    }}
                    data-testid="forgot-password-btn"
                  >
                    Forgot password?
                  </Link>
                </div>
                <Hcaptcha
                  sitekey={env.hcaptchaSitekey}
                  size="invisible"
                  onVerify={this.handleCallbackHcaptcha}
                  chalexpired-callback={this.handleExpiredCallbackHcaptcha}
                  onExpire={this.handleExpiredCallbackHcaptcha}
                  onError={this.handleExpiredCallbackHcaptcha}
                  open-callback={this.handleOpenCallbackHcaptcha}
                  id="hcaptchaWidget"
                />

                <div className="bottom-login" data-testid="not-a-member-yet-container">
                  Not a member yet?{' '}
                  <a href={`${env.envURL}/get-the-box/?utm_source=internal_login`}>
                    <span>Sign up</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

const mS = (state: {
  user: { username: string; password: string };
  view: { isMobile: boolean; messages: string[]; promptMessage: string };
}) => ({
  username: state.user.username,
  password: state.user.password,
  user: state.user,
  isMobile: state.view.isMobile,
  messages: state.view.messages,
  promptMessage: state.view.promptMessage,
});

const mD = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setUserData,
      setUsername,
      setPassword,
      setViewMessages,
      showPrompt,
    },
    dispatch,
  );

export default connect(mS, mD)(Home);
