import {
  createContext,
  useContext,
  useState,
  type FunctionComponent,
  type PropsWithChildren,
} from 'react';
import * as newMemberStates from '../helpers/constants/newMemberStates';
import { useMembershipInfo } from '../hooks/useMembershipInfo';

export const universalNavigationContextDefaultProps = {
  onNewMemberStateFetched: (_: unknown) => {},
  newMemberState: null,
  isNewMemberFlowFinished: true,
  isNewMember: false,
  newMemberStateLoading: true,
  membershipInfo: {},
  flashSaleId: null,
  setFlashSaleId: () => {},
};

export const UniversalNavigationContext = createContext({
  ...universalNavigationContextDefaultProps,
});

export const UniversalNavigationContextProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const [newMemberState, setNewMemberState] = useState(null);
  const [newMemberStateLoading, setNewMemberStateLoading] = useState(true);
  const [flashSaleId, setFlashSaleId] = useState<number | null>(null);
  const { membershipInfo } = useMembershipInfo();

  const isNewMember =
    newMemberState &&
    ![
      newMemberStates.PASSIVE_CHECKOUT,
      newMemberStates.ACTIVE_CHECKOUT,
      newMemberStates.TIMER_COMPLETED,
    ].includes(newMemberState);

  const isNewMemberFlowFinished =
    !newMemberState ||
    [
      newMemberStates.READY_FOR_SALE,
      newMemberStates.USER_ONBOARDING_1,
      newMemberStates.USER_ONBOARDING_2,
      newMemberStates.USER_ONBOARDING_3,
      newMemberStates.USER_ONBOARDING_4,
      newMemberStates.USER_COMPLETED,
      newMemberStates.PASSIVE_CHECKOUT,
      newMemberStates.ACTIVE_CHECKOUT,
      newMemberStates.TIMER_COMPLETED,
    ].includes(newMemberState);

  const onNewMemberStateFetched = (value) => {
    setNewMemberState(value);
    setNewMemberStateLoading(false);
  };

  return (
    <UniversalNavigationContext.Provider
      value={{
        newMemberState,
        newMemberStateLoading,
        onNewMemberStateFetched,
        isNewMemberFlowFinished,
        isNewMember,
        membershipInfo,
        flashSaleId,
        setFlashSaleId,
      }}
    >
      {children}
    </UniversalNavigationContext.Provider>
  );
};

export const useUniversalNavigationContext = () => {
  const context = useContext(UniversalNavigationContext);
  if (!context) {
    throw new Error(
      'You must use the UniversalNavigationContextProvider in order to consume this context.',
    );
  }
  return context;
};
