import { RefObject } from 'react';
import classNames from 'classnames';
import GlobalCart from '@fff-web/fff-cart';
import { getEnv } from '../../../../../../../environment';
import useInView from '../../../../../../hooks/useInView';
import { MENU_LABELS, ROUTES } from '../../constants';
import { isInvertedGTB } from '../../utils';
import { NavLink } from '../NavLink/NavLink';
import * as styles from './RightMenu.module.scss';

type Props = {
  showCart: boolean;
  stickyCartPage: boolean;
  isLoginPage: boolean;
  isPublicShop: boolean;
};

export const RightMenu = ({ showCart, stickyCartPage, isLoginPage, isPublicShop }: Props) => {
  const isInvertedGTBPage = isInvertedGTB();
  const env = getEnv();
  const LOGIN_URL = env.REACT_APP_LOGIN_BASE_URL;
  const { isInView, elementRef: headerRef } = useInView();
  const stickyCart = !isInView && stickyCartPage;

  const routes = [
    {
      name: MENU_LABELS.GIFT,
      url: ROUTES.GIFT,
      enabled: !isInvertedGTBPage && !isPublicShop,
    },
    {
      name: MENU_LABELS.GET_STARTED,
      url: ROUTES.GET_STARTED,
      enabled: isLoginPage,
    },
  ];

  const links = routes.filter((link) => link.enabled);

  return (
    <div
      className={classNames(styles.rightMenuContainer, {
        [styles.loginPageRightMenuContainer]: isLoginPage,
      })}
      data-testid="right-menu"
      ref={headerRef as RefObject<HTMLDivElement>}
    >
      {links.map((link, idx) => (
        <NavLink key={idx} {...link} />
      ))}

      {!isLoginPage ? <NavLink name={MENU_LABELS.LOGIN} url={LOGIN_URL} isLogin enabled /> : null}
      {showCart && (
        <div className={classNames(styles.globalCart, { 'sticky-cart': stickyCart })}>
          <GlobalCart env={env.name} />
        </div>
      )}
    </div>
  );
};
