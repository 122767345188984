export function isValidPasswordLength(password: string) {
  return password.length >= 8 && password.length <= 50;
}

export function isValidMinPasswordLength(password: string) {
  return password.length >= 8;
}

export function isValidMaxPasswordLength(password: string) {
  return password.length <= 50;
}

export function isContainUpperAndLowerCase(password: string) {
  return isContainLowerCase(password) && isContainUpperCase(password);
}

export function isContainLowerCase(password: string) {
  const lowerCaseRe = /[a-z]+/;
  return lowerCaseRe.test(password);
}

export function isContainUpperCase(password: string) {
  const upperCaseRe = /[A-Z]+/;
  return upperCaseRe.test(password);
}

export function isContainNumber(password: string) {
  const re = /[0-9]+/;
  return re.test(password);
}

export function isContainSpecialCharacter(password: string) {
  const re = /[\W_]+/;
  return re.test(password);
}
