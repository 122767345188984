import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ValidCookieForm from '../Home/ValidCookieForm';
import './WebOtp.css';
import { gotoPageAfterXSecond, isLoop, validOrigin, validShortLink } from '../../utils/commons';
import { checkAuth, processWebOtp } from '../../utils/data';
import env from '../../utils/env';
import { segmentEnum, track, trackPage } from '../../utils/segment';

const TERMS_OF_USE_AND_SALE = 'https://legal.fabfitfun.com/#terms-of-use';
const FABFITFUN_MEMBERSHIP_TERMS = 'https://legal.fabfitfun.com/#box-membership-terms';
const ERROR_UNKNOWN = -1;
const ERROR_EXPIRED_TOKEN = 4;
const ERROR_INTERNAL = 5;
const VALIDATION_VALID_TOKEN = 6;
class Otp extends Component {
  state = {
    isLoggingIn: false,
    otp: null,
    email: '',
    cta: '',
    isLoading: true,
    isValidCookie: false,
    isLooping: true,
    isCloseSuccessMessage: true,
    validateWebOtp: false,
  };
  constructor() {
    super();
    this.timeout = null;
    this.timeoutSubmit = null;
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    clearTimeout(this.timeoutSubmit);
  }

  parseTheFailedReason(code) {
    let error = 'unknown';
    switch (code) {
      case ERROR_EXPIRED_TOKEN:
        error = 'expired otp token';
        break;
      case ERROR_INTERNAL:
        error = 'internal error';
        break;
      default:
        error = 'unknown';
    }
    return error;
  }

  componentDidMount() {
    //Verify cookie and switch view accordingly
    checkAuth((res) => {
      let newSearchUrl = new URLSearchParams(this.props.location.search);
      let email = newSearchUrl.get('email');
      let otp = newSearchUrl.get('otp');
      let cta = newSearchUrl.get('cta');
      setTimeout(() => {
        this.setState((prevState) => ({
          ...prevState,
          isLoading: false,
          email: email,
          otp: otp,
          cta: cta,
        }));
      });
      let loop = true;
      if (!(validOrigin(cta) || validShortLink(cta))) {
        cta = env.envURL;
      }

      if (res.ok) {
        if (!isLoop()) {
          loop = false;
          gotoPageAfterXSecond({
            goto_url: cta,
          });
        }
      }

      this.setState({
        ...this.state,
        isValidCookie: res.ok,
        isLooping: loop,
        validateWebOtp: false,
      });
      processWebOtp(email, otp, cta, true).then((res) => {
        if (res.status) {
          switch (res.status) {
            case 200:
              res.json().then((json) => {
                if (json.code === VALIDATION_VALID_TOKEN) {
                  //valid otp in verify only mode
                  setTimeout(() => {
                    this.setState((prevState) => ({
                      ...prevState,
                      validateWebOtp: true,
                      isLoading: false,
                    }));
                  });
                  return;
                }

                track(segmentEnum.AutoLoginLink, {
                  success: false,
                  failed_reason: this.parseTheFailedReason(json.code),
                });
                gotoPageAfterXSecond({
                  goto_url: 'https://login.' + env.originURL + '/web-otp?error=' + json.code,
                });
              });
              break;
            case 400:
            case 422:
            default:
              gotoPageAfterXSecond({
                goto_url: 'https://login.' + env.originURL + '/web-otp?error=' + ERROR_UNKNOWN,
              });
              track(segmentEnum.AutoLoginLink, {
                success: false,
                failed_reason: this.parseTheFailedReason(ERROR_UNKNOWN),
              });
          }
        }
      });
      trackPage(segmentEnum.AutoLoginLink, { has_valid_cookie: res.ok });
    });
  }
  handleEnableSubmit = () => {
    clearTimeout(this.timeoutSubmit);
    this.setState({
      ...this.state,
      isLoggingIn: false,
    });
  };
  handleOtp = () => {
    this.setState({
      ...this.state,
      isLoggingIn: true,
    });
    this.timeoutSubmit = setTimeout(this.handleEnableSubmit, 30000);
    processWebOtp(this.state.email, this.state.otp, this.state.cta).then((res) => {
      if (res.status) {
        switch (res.status) {
          case 200:
            res.json().then((json) => {
              let redirectUrl = json.redirectUrl;
              if (json.code === 1) {
                track(segmentEnum.AutoLoginLink, {
                  success: true,
                });
                gotoPageAfterXSecond({
                  goto_url: redirectUrl,
                });
                return;
              }
              track(segmentEnum.AutoLoginLink, {
                success: false,
                failed_reason: this.parseTheFailedReason(json.code),
              });

              gotoPageAfterXSecond({
                goto_url: 'https://login.' + env.originURL + '/web-otp?error=' + json.code,
              });
              this.setState((prevState) => ({
                ...prevState,
                isLoading: false,
              }));
            });
            break;
          case 400:
          case 422:
          default:
            gotoPageAfterXSecond({
              goto_url: 'https://login.' + env.originURL + '/web-otp?error=' + ERROR_UNKNOWN,
            });
        }
      }
    });
  };

  render() {
    const { handleOtp } = this;

    const {
      isLoading,
      isLoggingIn,
      isValidCookie,
      isLooping,
      isCloseSuccessMessage,
      validateWebOtp,
    } = this.state;

    return (
      <Fragment>
        {isValidCookie ? (
          <ValidCookieForm isLooping={isLooping} />
        ) : (
          <>
            {!isLoading && validateWebOtp ? (
              <div className="web-otp-page mar-a">
                <div>
                  <div className="web-otp">
                    <p className="header" data-testid="otp-title">
                      Member Login
                    </p>
                    <p className="title title-padding-verify" data-testid="otp-subtext">
                      You requested a login link to sign in. Note that this link will expire and can
                      only be used once.
                    </p>
                    <form>
                      <div className="labelpair">
                        <label htmlFor="password" data-testid="otp-second-title">
                          Email Address
                          <span>*</span>
                        </label>
                        <input
                          name="email"
                          type="text"
                          value={this.state.email}
                          disabled="disabled"
                        />
                        <input
                          name="email"
                          data-testid="otp-input"
                          type="hidden"
                          value={this.state.email}
                        />
                      </div>
                    </form>
                    <div className="disclaimer" data-testid="disclaimer-text">
                      By logging in, you agree to our{' '}
                      <a href={TERMS_OF_USE_AND_SALE} data-testid="terms-of-use-btn">
                        <span>Terms of Use and Sale</span>
                      </a>{' '}
                      and{' '}
                      <a href={FABFITFUN_MEMBERSHIP_TERMS} data-testid="membership-terms-btn">
                        <span>FabFitFun Membership Terms</span>
                      </a>
                      .
                    </div>
                    <button
                      className="otp-button button-font"
                      data-testid="otp-btn"
                      type="button"
                      disabled={isLoggingIn || !isCloseSuccessMessage}
                      onClick={handleOtp}
                    >
                      {isLoggingIn ? (
                        <>
                          LOGGING IN &nbsp;
                          <i className="far fa-spinner fa-spin" />
                        </>
                      ) : (
                        <>LOGIN</>
                      )}
                    </button>
                  </div>
                </div>
                )
              </div>
            ) : (
              <div className="web-otp-page mar-a"> </div>
            )}
          </>
        )}
      </Fragment>
    );
  }
}

const mS = () => ({});

const mD = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mS, mD)(Otp);
