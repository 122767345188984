import env from './env';
import { sanitizeUrl } from './commons';

export const getQueryParam = (queryParamName) => {
  const href = new URL(window.location.href);
  const origin = href.searchParams.get(queryParamName);
  return origin || env.envURL;
};

export const getOriginParam = () => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('origin')) {
    let url = '';
    const entries = urlParams.entries();
    let i = 0;
    for (let param of entries) {
      const paramKey = param[0];
      let paramValue = param[1];
      const paramName = i !== 0 ? `&${paramKey}=` : '';
      url += paramName + paramValue;
      i++;
    }
    return sanitizeUrl(url);
  }
  return env.envURL;
};

export const getGetTheBoxUrl = () => {
  return env.envURL + '/get-the-box';
};

export const checkAuth = (cb) => sendRequest(`${env.login}/login/verify-cookie`, 'GET', cb);

export const getUserData = () => sendRequest(`${env.user}/self`, 'GET', (res) => res);

export const generateCookieHcaptcha = (username, password, hcaptchaToken) => {
  const auth = window.btoa(username + ':' + password);
  const origin = getOriginParam();

  return sendRequest(`${env.login}/login/generate-cookie-hcaptcha`, 'POST', (res) => res, {
    Authorization: 'Basic ' + auth,
    'Redirect-Url': origin,
    'Hcaptcha-Token': hcaptchaToken,
  });
};

export const updatePassword = (email, password, resetKey) => {
  return sendRequestWithBody(
    `${env.user}/reset-password`,
    'PATCH',
    (res) => res,
    {},
    {
      password: password,
      email: email,
      resetKey: resetKey,
    },
  );
};

export const verifyResetKey = (resetKey, email) => {
  return sendRequestWithBody(
    `${env.login}/password/verify-reset-key`,
    'POST',
    (res) => res,
    {},
    { email: email, resetKey: resetKey },
  );
};

export const sendResetPasswordEmail = (resetPasswordRequest) => {
  return sendRequestWithBody(
    `${env.login}/password/send-reset-password-email`,
    'POST',
    (res) => res,
    {},
    resetPasswordRequest,
  );
};

export const logout = (cb) => sendRequest(`${env.login}/login/logout`, 'GET', cb);

export const checkWhitelistIpAddress = (cb) =>
  sendRequest(`${env.login}/login/check-whitelist-ip`, 'POST', cb);

export const sendRequest = (path, method = 'GET', cb = () => {}, headers = {}) => {
  const defaultHeaders = {
    'FFF-Auth': 'V1.1',
    Origin: env.originURL,
    'Content-Type': 'application/json; charset=utf-8',
  };
  return fetch(path, {
    credentials: 'include',
    headers: { ...defaultHeaders, ...headers },
    data: {
      activatedOn: true,
    },
    redirect: 'manual',
    mode: 'cors',
    method,
  })
    .then((response) => cb(response))
    .catch((err) => {
      console.error('Fetch Failed: ', err);
      return err;
    });
};

export const processOtp = (email, otp, cta) => {
  return sendRequestWithBody(
    `${env.login}/otp-token`,
    'POST',
    (res) => res,
    {},
    {
      otp: otp,
      email: email,
      cta: cta,
    },
  );
};

export const processWebOtp = (email, otp, cta, verifyOnly) => {
  return sendRequestWithBody(
    `${env.login}/otp-web-token/verify`,
    'POST',
    (res) => res,
    {},
    {
      otp: otp,
      email: email,
      cta: cta,
      verifyOnly: verifyOnly,
    },
  );
};

export const sendRequestWithBody = (
  path,
  method = 'POST',
  cb = () => {},
  headers = {},
  body = {},
) => {
  const defaultHeaders = {
    Origin: env.originURL,
    'Content-Type': 'application/json; charset=utf-8',
  };
  return fetch(path, {
    credentials: 'include',
    headers: { ...defaultHeaders, ...headers },
    data: {
      activatedOn: true,
    },
    redirect: 'manual',
    mode: 'cors',
    body: JSON.stringify(body),
    method,
  })
    .then((response) => cb(response))
    .catch((err) => {
      console.error('Fetch Failed: ', err);
      return err;
    });
};

export const requestWebOtp = (request, hcaptchaToken) => {
  return sendRequestWithBody(
    `${env.login}/otp-web-token/create`,
    'POST',
    (res) => res,
    {
      'Hcaptcha-Token': hcaptchaToken,
    },
    request,
  );
};

export default sendRequest;
