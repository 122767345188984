import { useEffect } from 'react';
import { gotoPageAfterXSecond } from '../../utils/commons';
import { getGetTheBoxUrl, logout } from '../../utils/data';
import './Logout.css';
import brandLogo from './img/brand-logomark.svg';

const Logout = () => {
  useEffect(() => {
    logout(() => {
      gotoPageAfterXSecond({
        goto_url: getGetTheBoxUrl(),
      });
    });
  }, []);

  return (
    <div className="container">
      <div className="loadingIconContainer">
        <img src={brandLogo} className="loadingIcon" alt="brand logo loading" />
      </div>
    </div>
  );
};

export default Logout;
