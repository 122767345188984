import env from './env';
import { Cookies } from '@fff-web/fff-utilities';

const LOGIN_COUNT = 'login_count';

/**
 * Check if the user is looping
 * @returns {boolean} True if the user is looping, false otherwise
 */
export function isLoop() {
  let count = Cookies.getCookie(LOGIN_COUNT);
  if (count) {
    count = parseInt(count, 10);
    count++;
  } else {
    count = 0;
  }

  Cookies.setCookie({
    key: LOGIN_COUNT,
    value: count.toString(),
    maxAge: 60,
    path: '/',
  });

  return count >= 10;
}

/**
 * Go to the page after X seconds
 * @param {object} params - Parameters
 */
export function gotoPageAfterXSecond(params) {
  if (typeof params !== 'undefined' && Object.prototype.hasOwnProperty.call(params, 'goto_url')) {
    setTimeout(function () {
      window.location = params['goto_url'];
    }, 2000);
  }
}

/**
 * Check if the object has nested properties
 * @param {object} obj - Object
 * @param {...string} args - Properties
 * @returns {boolean} True if the object has nested properties, false otherwise
 */
export function checkNested(obj /*, level1, level2, ... levelN*/) {
  var args = Array.prototype.slice.call(arguments, 1);

  for (var i = 0; i < args.length; i++) {
    if (!obj || !Object.prototype.hasOwnProperty.call(obj, args[i])) {
      return false;
    }
    obj = obj[args[i]];
  }
  return true;
}

/**
 * Sanitize the URL
 * @param {string} url - URL
 * @returns {string} Sanitized URL
 */
export function sanitizeUrl(url) {
  if (containHostName(url)) {
    url = addHttpsIfMissing(url);
  }

  try {
    new URL(url);
  } catch (_) {
    //invalid URL, add default domain
    let slash = '';
    if (url.charAt(0) !== '/') {
      slash = '/';
    }

    url = env.envURL + slash + url;
  }
  return url;
}

/**
 * Check if the origin is valid
 * @param {string|null} origin - Origin
 * @returns {boolean} True if the origin is valid, false otherwise
 */
export function validOrigin(origin) {
  try {
    return new URL(origin).host.endsWith(env.originURL);
  } catch (_) {
    return false;
  }
}

/**
 * Check if the URL is a valid short link
 * @param {string|null} url - URL
 * @returns {boolean} True if the URL is a valid short link, false otherwise
 */
export function validShortLink(url) {
  try {
    return new URL(url).host.endsWith(env.shortURL);
  } catch (_) {
    return false;
  }
}

/**
 * Scroll to the top of the page in desktop mode
 */
export function scrollTopInDesktopMode() {
  if (window.innerWidth >= 760) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
}

/**
 * Check if the origin contains a host name
 * @param {string} origin - Origin
 * @returns {boolean} True if the origin contains a host name, false otherwise
 */
function containHostName(origin) {
  let pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(origin);
}

/**
 * Add HTTPS if missing
 * @param {string} url - URL
 * @returns {string} URL with HTTPS
 */
function addHttpsIfMissing(url) {
  if (!/^(?:f|ht)tps?:\/\//.test(url)) {
    url = 'https://' + url;
  }
  return url;
}
