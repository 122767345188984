import { ScriptsExists } from '@fff-web/fff-utilities';
import type { IUser } from '@fff-web/fff-utilities';
import { adaScript, adaStyle, gladlyScript } from './chatSnippets';
import { getHomepageHost, getZendesk } from './getEnv';
import isBrowser from './isBrowser';

const zendeskScriptId = 'ze-snippet';
const adaScriptId = '__ada';
const gladlyScriptId = '__gladly';
const dixaScriptId = '__dixa';

const adaScriptSrc = 'https://static.ada.support/embed2.js';

const adaDataHandle = 'fff';
const adaAIHandle = 'fff-gr';

const dixaScriptSrc = 'https://messenger.dixa.io/bootstrap.js';
const dixaMessengerToken = '5408034e7793401ea99b969ca0d16970';

/**
 *  Checks if the Ada button and the script exists in the page.
 * @returns {boolean} If the Ada chatbot is loaded.
 */
export const getIsAdaChatLoaded = () => {
  const adaChatButtonEl = document.getElementById('ada-entry');

  return adaChatButtonEl && ScriptsExists.scriptExistsById(adaScriptId);
};

/**
 * Toggles the state of the button between display/hide the button.
 * @param {boolean} showChatButton The initial value to display the Ada chat button.
 * @returns {void}
 */
const toggleDisplayAdaChat = (showChatButton = false) => {
  const adaChatButtonEl = document.getElementById('ada-entry');
  adaChatButtonEl.style.display = `${showChatButton ? 'initial' : 'none'}`;
};

/**
 * Removes all the existing widgets in the page.
 * @returns {void}
 */
export const removeAllWidgets = () => {
  removeScriptIfPresent(gladlyScriptId);
  removeScriptIfPresent(zendeskScriptId);
  removeScriptIfPresent(adaScriptId);
  removeScriptIfPresent(dixaScriptId);
};

/**
 * Loads the Zendesk script into the page.
 * @returns {boolean} If the Zendesk chat was loaded successfully.
 */
const loadZendesk = () => {
  const zenKey = getZendesk();
  const scriptSrc = `https://static.zdassets.com/ekr/snippet.js?key=${zenKey}`;
  if (!ScriptsExists.scriptExistsById(zendeskScriptId)) {
    const blockScript = document.createElement('script');
    blockScript.id = zendeskScriptId;
    blockScript.src = scriptSrc;
    blockScript.type = 'text/javascript';
    document.head.appendChild(blockScript);
  }
  return true;
};

/**
 * Loads the Gladly script into the page.
 * @returns {boolean} If the Zendesk chat was loaded successfully.
 */
export const loadGladlyChat = () => {
  const blockScript = document.createElement('script');
  blockScript.innerHTML = gladlyScript;
  blockScript.id = gladlyScriptId;
  blockScript.type = 'text/javascript';
  document.head.appendChild(blockScript);
  window.gladlyConfig = { appId: 'fabfitfun.com' };
  return true;
};

/**
 * Loads the proper settings script and the Ada chatbot script to the page.
 */
const loadAdaChat = (userInfo: IUser, isAIAdaChat: boolean) => {
  const isAdaChatbotLoaded = getIsAdaChatLoaded();

  if (isAdaChatbotLoaded) {
    toggleDisplayAdaChat(true);
    return;
  }

  document.head.insertAdjacentHTML('beforeend', adaStyle);
  window.liveChatEnded = false;

  const adaScriptEl = document.createElement('script');
  adaScriptEl.innerHTML = adaScript;
  adaScriptEl.type = 'text/javascript';
  document.body.appendChild(adaScriptEl);
  if (userInfo?.email) {
    window.adaSettings = {
      ...window.adaSettings,
      metaFields: userInfo,
    };
  }

  /**
   * This script must be loaded after the 'window.adaSettings'
   * (added in 'adaScriptEl') is being declared.
   */
  if (!ScriptsExists.scriptExistsById(adaScriptId)) {
    const blockScript = document.createElement('script');
    blockScript.id = adaScriptId;
    blockScript.setAttribute('data-handle', isAIAdaChat ? adaAIHandle : adaDataHandle);
    blockScript.src = adaScriptSrc;
    blockScript.type = 'text/javascript';
    document.body.appendChild(blockScript);
  }
};

/**
 * Checks if a script is present in the page and if it's present
 * it's being removed from the page.
 * @param {string} scriptId The name of the script to remove.
 * @returns {void}
 */
const removeScriptIfPresent = (scriptId) => {
  const isAdaChatbotLoaded = getIsAdaChatLoaded();

  if (scriptId === adaScriptId) {
    if (isAdaChatbotLoaded) {
      toggleDisplayAdaChat();
      return;
    }
  }
  const element = document.getElementById(scriptId);

  if (element) {
    console.log(`removing scriptId ${scriptId}`);
    element.parentNode.removeChild(element);
  }
};

/**
 * Initializes and loads the Dixa chat widget
 * @returns {void}
 */
const loadDixaChat = () => {
  if (window._dixa_) return;

  // Initialize Dixa object
  window._dixa_ = {
    invoke: function (method, args) {
      window._dixa_.pendingCalls = window._dixa_.pendingCalls || [];
      window._dixa_.pendingCalls.push([method, args]);
    },
    addListener: function (event, listener) {
      window._dixa_.pendingAddListenerCalls = window._dixa_.pendingAddListenerCalls || [];
      window._dixa_.pendingAddListenerCalls.push([event, listener]);
    },
  };

  // Create and append Dixa script
  const dixaScript = document.createElement('script');
  dixaScript.id = dixaScriptId;
  dixaScript.type = 'text/javascript';
  dixaScript.setAttribute('charset', 'utf-8');
  dixaScript.async = true;
  dixaScript.src = dixaScriptSrc;

  const firstScript = document.getElementsByTagName('script')[0];
  firstScript.parentNode?.insertBefore(dixaScript, firstScript);

  // Initialize the messenger
  window._dixa_.invoke('init', { messengerToken: dixaMessengerToken });
};

/**
 * Loads the chat and its settings according to the configurations provided.
 */
export const loadChat = (
  chatConfig: { supportChat: string } & Record<string, unknown[]>,
  userInfo: IUser,
) => {
  if (!isBrowser()) {
    return false;
  }

  if (!chatConfig) {
    return false;
  }
  let currentPath = window.location && window.location.pathname ? window.location.pathname : '';

  if (currentPath !== '/') {
    // This condition is to render the chat on GET THE BOX page
    if (currentPath.endsWith('/')) {
      currentPath = currentPath.substr(0, currentPath.length - 1);
    }
  }

  if (currentPath.length === 0 && window.location.host === getHomepageHost()) {
    currentPath = '/homepage';
  }

  const isSupportPage = window.location.host.includes('support');
  const isShipmentTrackingPage = window.location.pathname.includes('track-shipment');
  const pagesWithZendeskChat = chatConfig.zendeskPages || [];
  const pagesWithGladlyChat = chatConfig.gladlyPages || [];
  const pagesWithAdaChat = chatConfig.adaPages || [];
  const pagesWithAIAdaChat = chatConfig.aiAdaPages || [];
  const pagesWithDixaChat = chatConfig.dixaPages || [];
  const isAIAdaChat =
    pagesWithAIAdaChat.includes(currentPath) ||
    (isSupportPage && chatConfig.supportChat === adaScriptId) ||
    (isShipmentTrackingPage && chatConfig.supportChat === adaScriptId);
  const useAda =
    pagesWithAdaChat.includes(currentPath) ||
    (chatConfig.supportChat === adaScriptId && isSupportPage) ||
    isAIAdaChat;
  const useZendesk =
    pagesWithZendeskChat.includes(currentPath) ||
    (chatConfig.supportChat === zendeskScriptId && isSupportPage);
  const useGladly =
    pagesWithGladlyChat.includes(currentPath) ||
    (chatConfig.supportChat === gladlyScriptId && isSupportPage);
  const useDixa =
    pagesWithDixaChat.includes(currentPath) ||
    (chatConfig.supportChat === dixaScriptId && isSupportPage);

  if (useGladly) {
    console.log('Gladly chat loaded');
    removeAllWidgets();
    return loadGladlyChat();
  }

  if (useZendesk) {
    console.log('Zendesk chat loaded');
    removeAllWidgets();
    return loadZendesk();
  }

  if (useAda) {
    console.log('Ada chat loaded');
    removeAllWidgets();
    removeScriptIfPresent(zendeskScriptId);
    return loadAdaChat(userInfo, isAIAdaChat);
  }

  if (useDixa) {
    console.log('Dixa chat loaded');
    removeAllWidgets();
    return loadDixaChat();
  }

  removeAllWidgets();
  console.log('No chat loaded');
  return false;
};

// Add TypeScript interface for window._dixa_
declare global {
  interface Window {
    _dixa_?: {
      invoke: (method: string, args: unknown) => void;
      addListener: (event: string, listener: unknown) => void;
      pendingCalls?: [string, unknown][];
      pendingAddListenerCalls?: [string, unknown][];
    };
  }
}
