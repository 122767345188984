import classNames from 'classnames';
import { getEnv } from '../../../../../environment';
import { MENU_LABELS, ROUTES } from '../ProspectNavBar/constants';
import { isInvertedGTB } from '../ProspectNavBar/utils';
import * as styles from './MobileMenu.module.scss';

type Props = {
  isOpenMenu: boolean;
  isLoginPage?: boolean;
  isPublicShop?: boolean;
};

const MobileMenu = ({ isOpenMenu, isLoginPage, isPublicShop }: Props) => {
  const navigationMobile = classNames(styles.mobileMenu, {
    [styles.showMenu]: isOpenMenu,
  });
  const isInvertedGTBPage = isInvertedGTB();
  const env = getEnv();
  const baseUrl = env.REACT_APP_BASE_URL;
  const loginUrl = env.REACT_APP_LOGIN_BASE_URL;
  const routes = [
    {
      name: MENU_LABELS.GIFT,
      url: `${baseUrl}/gift`,
      enabled: !(isInvertedGTBPage || isPublicShop),
    },
  ];

  const links = routes.filter((link) => link.enabled);

  return (
    <div data-testid="mobile-menu" data-is-open={isOpenMenu} className={navigationMobile}>
      <ul role="menu">
        {links.map((route) => (
          <li key={route.name}>
            <a href={route.url}>{route.name}</a>
          </li>
        ))}
        {isLoginPage ? (
          <li>
            <a href={`${baseUrl}${ROUTES.GET_STARTED}`}>{MENU_LABELS.GET_STARTED}</a>
          </li>
        ) : (
          <li>
            <a href={loginUrl}>{MENU_LABELS.LOGIN}</a>
          </li>
        )}
      </ul>
    </div>
  );
};

export default MobileMenu;
