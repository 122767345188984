import { GROWTH_GLOBAL_NAV } from '../../../../utils/featureFlagVariants/growth-global-nav';
import { NavLink } from '../ProspectNavBar/components/NavLink/NavLink';
import * as styles from './ProspectBanner.module.scss';

type Props = {
  variationKey: string;
  isPublicShop: boolean;
};

const MENU_LABELS = {
  membership: 'membership',
  shop: 'shop',
  quiz: 'take the quiz',
};

const ProspectBanner = ({ variationKey, isPublicShop }: Props) => {
  const routes = [
    {
      name: MENU_LABELS.membership,
      url: '/get-the-box#how-it-works-section',
      enabled: true,
    },
    {
      name: MENU_LABELS.shop,
      url: '/shop/?utm_source=gtb',
      enabled: isPublicShop || GROWTH_GLOBAL_NAV.VARIATION_C === variationKey,
    },
    {
      name: MENU_LABELS.quiz,
      url: '/get-the-box/quiz/',
      enabled: GROWTH_GLOBAL_NAV.VARIATION_E === variationKey,
    },
  ];

  const links = routes.filter((link) => link.enabled);

  return (
    <div data-testid="header-banner" className={styles.banner}>
      {links.map((link) => {
        return <NavLink key={link.name} style={styles.navLink} {...link} />;
      })}
    </div>
  );
};

export default ProspectBanner;
