import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { initialize } from '@fff-web/fff-analytics';
import App from './App';
import store from './store';
import './index.css';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_CONTAINER_ID!,
};
TagManager.initialize(tagManagerArgs);

const container = document.querySelector('#root');
const root = createRoot(container!);

globalThis.window.FFF = { ...globalThis.window.FFF };
globalThis.window.FFF[process.env.APP_NAME] = {
  version: process.env.APP_VERSION,
  libs: process.env.APP_LIBS,
};

initialize();

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <div>
        <App />
      </div>
    </BrowserRouter>
  </Provider>,
);
